import { PencilSquareIcon, SparklesIcon, WrenchScrewdriverIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { InfiniteTuskIssues } from "./MergedTuskIssues";

const features = [
  {
    name: "Fix customers' bugs",
    description:
      "Customer-reported bugs can be time-consuming to investigate and tackle. Let Tusk have the first pass at fixing them.",
    // href: '#',
    icon: WrenchScrewdriverIcon,
  },
  {
    name: "Make copy changes",
    description:
      "We know you have better things to do than removing a word in a header, or finding and replacing a term across multiple files.",
    // href: '#',
    icon: PencilSquareIcon,
  },
  {
    name: "Improve your user experience",
    description:
      "Have Tusk go through your product quality backlog. We'll implement the UI/UX changes that you've been neglecting for weeks.",
    // href: '#',
    icon: SparklesIcon,
  },
];

export const UseCases = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="text-base font-semibold leading-7 text-purple-600">
            Increase customer happiness
          </h2>
          <p className="mt-2 text-3xl font-display tracking-tight text-slate-900 sm:text-4xl">
            Achieve a bug-free product that delights your customers
          </p>
          <p className="mt-6 text-lg tracking-tight leading-8 text-slate-600">
            With Tusk, you can polish features down to the last detail without distracting your
            engineers. Prevent your product's death by a thousand cuts.
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
            {features.map((feature) => (
              <div key={feature.name} className="flex flex-col">
                <dt className="text-lg font-display leading-7 text-slate-900">
                  <div className="mb-6 flex h-10 w-10 items-center justify-center rounded-lg bg-purple-600">
                    <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                  </div>
                  {feature.name}
                </dt>
                <dd className="mt-1 flex flex-auto flex-col text-sm leading-7 text-slate-700">
                  <p className="flex-auto">{feature.description}</p>
                  {/* <p className="mt-6">
                    <Link
                      onClick={() => loginWithRedirect()}
                      className="text-sm font-semibold leading-6 text-purple-600 hover:text-purple-300"
                      to={""}
                    >
                      Try now <span aria-hidden="true">→</span>
                    </Link>
                  </p> */}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
};
