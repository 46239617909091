// Generated with Bifrost on 8/12/2023, 8:31:38 AM
// Figma Link: https://www.figma.com/file/p4oSPeFOhqNPliouIl0d1S?node-id=51:26283
import { Logo } from "../marketing/components";
import { Button } from "../marketing/components";
import { ProgressBar } from "../components";

export const BifrostTest = () => {
  return (
    <div className="bg-white flex-col w-[1440px] flex items-center h-fit">
      <div className="bg-white border-b border-[#f2f3f6]">
        {/* This frame above (node Dropdown header navigation) does not use autolayout - it may display incorrectly */}
        <div className="flex-col h-full w-full flex justify-center items-center">
          <div className="w-[1280px] flex justify-between items-center px-8">
            <div className="flex items-center gap-10">
              <Logo />
              <div className="flex items-center gap-8">
                <Button>Home</Button>
                <div className="flex-col flex items-center py-1">
                  <Button>Products</Button>
                </div>
                <div className="flex-col flex items-center py-1">
                  <Button>Resources</Button>
                </div>
                <Button>Pricing</Button>
              </div>
            </div>
            <div className="flex items-center gap-3">
              <Button>Sign out</Button>
            </div>
          </div>
        </div>
      </div>
      <div className="left-[159px] top-[30px] absolute text-xl leading-[20px] font-semibold text-[#101828] font-Inter">
        Tusk
      </div>
      <div className="bg-white w-full flex-col h-11/12 flex items-center gap-8 px-8 pt-24 pb-12">
        <div className="relative flex-col w-[635px] flex items-center gap-8">
          <div className="w-full flex-col flex items-center gap-6 text-center font-Inter">
            <div className="w-full flex-col flex items-start gap-3">
              <div className="text-3xl leading-[38px] font-semibold text-[#0f1728] w-[635px]">
                Tell us about yourself
              </div>
              <div className="text-base text-[#667084] w-[635px]">
                We’ll use these details to optimize your experience.
              </div>
            </div>
          </div>
          <ProgressBar />
          <div>
            {/* This frame above (node Group 1000004049) does not use autolayout - it may display incorrectly */}
            <div className="bg-white border border-[#e6e6e6] rounded-lg h-[419px] w-[636px]" />
            <div>
              {/* This frame above (node Group 1000004048) does not use autolayout - it may display incorrectly */}
              <div>
                {/* This frame above (node Group 1000003985) does not use autolayout - it may display incorrectly */}
                <div className="text-base font-medium text-[#212b36] font-Inter">
                  How did you hear about us?
                </div>
                <div>
                  {/* This frame above (node Group 1000002380) does not use autolayout - it may display incorrectly */}
                  <div className="bg-white border border-grey-200 rounded-[6px] h-[46px] w-[580px]" />
                  <div className="text-base text-[#212b36] font-Inter">Word of mouth</div>
                  <img
                    src="/images/Onboarding 1-0/Vector.svg"
                    alt="Vector"
                    className="h-[7px] w-3.5"
                  />
                </div>
              </div>
              <div>
                {/* This frame above (node Group 1000002586) does not use autolayout - it may display incorrectly */}
                <div>
                  {/* This frame above (node Group 1000002380) does not use autolayout - it may display incorrectly */}
                  <div className="bg-white border border-grey-200 rounded-[6px] h-[46px] w-[580px]" />
                  <div className="text-base text-[#212b36] font-Inter">
                    Automating some of my product team’s UI/UX tickets.
                  </div>
                </div>
                <div className="text-base font-medium text-[#212b36] font-Inter">
                  How are you planning on using Tusk?
                </div>
              </div>
              <div>
                {/* This frame above (node Group 1000002583) does not use autolayout - it may display incorrectly */}
                <div>
                  {/* This frame above (node Group 1000002380) does not use autolayout - it may display incorrectly */}
                  <div className="bg-white border border-grey-200 rounded-[6px] h-[46px] w-[279px]" />
                  <img src="/images/Onboarding 1-0/user.svg" alt="user" className="h-5 w-5" />
                  <div className="text-base text-[#212b36] font-Inter">Olivia Rhye</div>
                </div>
                <div className="text-base font-medium text-[#212b36] font-Inter">Full Name</div>
              </div>
              <div>
                {/* This frame above (node Group 1000002584) does not use autolayout - it may display incorrectly */}
                <div>
                  {/* This frame above (node Group 1000002380) does not use autolayout - it may display incorrectly */}
                  <div className="bg-white border border-grey-200 rounded-[6px] h-[46px] w-[279px]" />
                  <div className="text-base text-[#212b36] font-Inter">Product Manager</div>
                  <img
                    src="/images/Onboarding 1-0/Vector-2.svg"
                    alt="Vector"
                    className="h-[7px] w-3.5"
                  />
                </div>
                <div className="text-base font-medium text-[#212b36] font-Inter">Role</div>
              </div>
            </div>
            <div className="text-base font-medium text-[#212b36] font-Inter">
              Personal Information
            </div>
            <img
              src="/images/Onboarding 1-0/Line-638.svg"
              alt="Line 638"
              className="h-[0px] w-[636px]"
            />
          </div>
          <div className="bg-[#3056d3] right-[0px] bottom-[-65px] absolute flex items-start py-3.5 pr-[30px] pl-[31px] rounded-[5px] text-base leading-[20px] font-semibold text-center text-white font-Inter">
            <div>Continue</div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BifrostTest;
