import React from "react";
import clsx from "clsx";

export interface IToolbarOption {
  Icon: React.ElementType;
  onClickHandler: () => void;
  disabled: boolean;
  hoverText: string;
}

export interface IToolbarProps {
  toolbarOptions: IToolbarOption[];
}

export const Toolbar: React.FC<IToolbarProps> = ({ toolbarOptions }) => {
  return (
    <div className={clsx("relative", { "pt-9": toolbarOptions.length === 0 })}>
      <div aria-hidden="true" className="absolute inset-0 flex items-center">
        <div className="w-full border-t border-gray-300" />
      </div>
      {toolbarOptions.length > 0 && (
        <div className="relative flex justify-center">
          <span className="isolate inline-flex -space-x-px rounded-md shadow-sm">
            {toolbarOptions.map(({ Icon, onClickHandler, disabled, hoverText }, index) => (
              <div key={index} className="relative group">
                <button
                  onClick={onClickHandler}
                  type="button"
                  className={clsx(
                    "relative inline-flex items-center bg-white px-3 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10",
                    {
                      "opacity-50 cursor-not-allowed": disabled,
                      "rounded-l-md": index === 0 && toolbarOptions.length > 1,
                      "rounded-r-md":
                        index === toolbarOptions.length - 1 && toolbarOptions.length > 1,
                    },
                  )}
                  aria-disabled={disabled}
                >
                  <span className="sr-only">{hoverText}</span>
                  <Icon aria-hidden="true" className="h-4 w-4" />
                </button>
                <div className="absolute top-full mt-2 hidden group-hover:block bg-gray-500 text-white text-xs rounded py-1 px-2">
                  {hoverText}
                </div>
              </div>
            ))}
          </span>
        </div>
      )}
    </div>
  );
};
