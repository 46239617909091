import { clsx } from "clsx";
import { useNavigate, useLocation, Routes, Route, Navigate } from "react-router-dom";
import {
  GithubSettings,
  SubscriptionSettings,
  IntegrationSettings,
  UserSettings,
} from "../containers";
import { PageHeading } from "../components";

export const SettingsPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const tabs = [
    { name: "Integrations", href: "/app/settings/integrations", current: false },
    { name: "GitHub", href: "/app/settings/github", current: false },
    { name: "Subscription", href: "/app/settings/subscription", current: false },
    { name: "Team", href: "/app/settings/team", current: false },
  ];

  return (
    <>
      <PageHeading name="Settings" />
      <div className="border-b border-gray-200 pb-5 mb-8 sm:pb-0">
        <div>
          <div className="sm:hidden">
            <label htmlFor="current-tab" className="sr-only">
              Select a tab
            </label>
            <select
              id="current-tab"
              name="current-tab"
              className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-purple-500 focus:outline-none focus:ring-purple-500 sm:text-sm"
              onChange={(e) => navigate(e.target.value)}
            >
              {tabs.map((tab) => (
                <option key={tab.name} value={tab.href}>
                  {tab.name}
                </option>
              ))}
            </select>
          </div>
          <div className="hidden sm:block">
            <nav className="-mb-px flex space-x-8">
              {tabs.map((tab) => (
                <a
                  key={tab.name}
                  onClick={() => navigate(tab.href)}
                  className={clsx(
                    tab.href === location.pathname
                      ? "border-purple-500 text-purple-600"
                      : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                    "whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium hover:cursor-pointer",
                  )}
                  aria-current={tab.href === location.pathname ? "page" : undefined}
                >
                  {tab.name}
                </a>
              ))}
            </nav>
          </div>
        </div>
      </div>
      <div>
        <Routes>
          <Route path="integrations" element={<IntegrationSettings />} />
          <Route path="github" element={<GithubSettings />} />
          <Route path="subscription" element={<SubscriptionSettings />} />
          <Route path="team" element={<UserSettings />} />
          <Route path="/" element={<Navigate to="/app/settings/integrations" />} />
        </Routes>
      </div>
    </>
  );
};
