import { useMemo } from "react";

import { ConnectExternalResource } from "../../components";
import { useAppContext } from "../../providers";
import { GetResourcesQuery } from "../../__generatedGQL__/graphql";
import { join } from "lodash";

const JIRA_SCOPES = [
  "offline_access",
  "read:me",
  "read:account",
  "read:jira-work",
  "read:jira-user",
  "write:jira-work",
  "manage:jira-webhook",
];

export const JiraAuth = ({ resource }: { resource?: GetResourcesQuery["resources"][0] }) => {
  const { user, selectedClientId } = useAppContext();

  const jiraEnabled = !!resource;

  const jiraAuthUrl = useMemo(() => {
    const state = {
      userId: user.id,
      clientId: selectedClientId,
    };
    const urlState = encodeURIComponent(JSON.stringify(state));
    const scopes = encodeURIComponent(join(JIRA_SCOPES, " "));

    return `https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=${process.env.JIRA_CLIENT_ID}&scope=${scopes}&redirect_uri=${process.env.JIRA_REDIRECT_URI}&response_type=code&prompt=consent&state=${urlState}`;
  }, [user.id, selectedClientId]);

  return (
    <ConnectExternalResource
      authed={jiraEnabled}
      onConnect={() => window.location.assign(jiraAuthUrl)}
      resourceName="Jira"
      resourceLogo="https://asset.brandfetch.io/id63p8eMbd/idUWOKD8cb.png"
      resourceDescription="Assign issues using a Jira label"
    />
  );
};
