import { useSearchParams } from "react-router-dom";
import JsonView from "@uiw/react-json-view";
import { useEffect, useState } from "react";
import { map } from "lodash";
import { clsx } from "clsx";
// import Editor, { useMonaco } from "@monaco-editor/react";

import { useAppContext } from "../providers";

const ADMIN_BASE_URL = `${process.env.API_BASE_URL}/api/admin`;

export const AdminPage = () => {
  const { user, authToken } = useAppContext();
  const [searchParams, _] = useSearchParams();
  // const monaco = useMonaco();

  const [rightPane, setRightPane] = useState<"file" | "search">(null);

  const [fileTree, setFileTree] = useState<any>({});
  const [repo, setRepo] = useState<any>({});

  const [repoId, setRepoId] = useState<number>(null);
  const [filePath, setFilePath] = useState<string>(null);
  const [fileContent, setFileContent] = useState<string>(null);
  const [fileLanguage, setFileLanguage] = useState<string>(null);
  const [isLoadingFileContent, setIsLoadingFileContent] = useState<boolean>(false);
  const [showTree, setShowTree] = useState<boolean>(false);
  const [isLoadingFileTree, setIsLoadingFileTree] = useState<boolean>(false);

  const [searchType, setSearchType] = useState<"grep" | "semantic" | "filePath">("grep");
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [isLoadingSearchResults, setIsLoadingSearchResults] = useState<boolean>(false);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleSearch = () => {
    setRightPane("search");
    setIsLoadingSearchResults(true);

    fetch(
      `${ADMIN_BASE_URL}/repo/search?repoId=${repoId}&searchQuery=${searchTerm}&searchType=${searchType}`,
      {
        headers: { authorization: `Bearer ${authToken}` },
      },
    )
      .then((res) => res.json())
      .then((data) => {
        setSearchResults(data);
        setIsLoadingSearchResults(false);
      });
  };

  useEffect(() => {
    if (rightPane === "file" && filePath) {
      document.title = `${filePath.split("/").pop()} (${repo.name})`;
    } else if (rightPane === "search") {
      document.title = `${searchTerm} (${searchType} - ${repo.name})`;
    } else {
      document.title = `${repo.ownerName}/${repo.name}`;
    }
  }, [rightPane, filePath, repo, searchTerm, searchType]);

  useEffect(() => {
    if (searchParams.get("showTree")) {
      setShowTree(true);
    }

    if (searchParams.get("repoId")) {
      setRepoId(parseInt(searchParams.get("repoId")));
    }
    if (searchParams.get("filePath")) {
      setFilePath(searchParams.get("filePath"));
      setRightPane("file");
    }
  }, [searchParams]);

  useEffect(() => {
    if (!repoId) {
      return;
    }

    fetch(`${ADMIN_BASE_URL}/repo?repoId=${repoId}`, {
      headers: { authorization: `Bearer ${authToken}` },
    })
      .then((res) => res.json())
      .then((data) => {
        setRepo(data);
      });
  }, [repoId]);

  useEffect(() => {
    if (!showTree) {
      return;
    }

    setIsLoadingFileTree(true);
    fetch(`${ADMIN_BASE_URL}/repo/tree?repoId=${searchParams.get("repoId")}`, {
      headers: { authorization: `Bearer ${authToken}` },
    })
      .then((res) => res.json())
      .then((data) => {
        setFileTree(data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoadingFileTree(false);
      });
  }, [repoId, showTree, authToken]);

  useEffect(() => {
    if (!filePath) {
      return;
    }

    setRightPane("file");
    setIsLoadingFileContent(true);
    fetch(`${ADMIN_BASE_URL}/repo/file?repoId=${repoId}&filePath=${filePath}`, {
      headers: { authorization: `Bearer ${authToken}` },
    })
      .then((res) => res.json())
      .then((data) => {
        setFileContent(data.file);
        setFileLanguage(data.language);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoadingFileContent(false);
      });
  }, [filePath, repoId, authToken]);

  if (!user.isAdmin) {
    return null;
  }

  return (
    <>
      <div className="flex flex-col mx-4">
        <div className="my-4">
          <div className="flex items-center space-x-6">
            <div className="font-semibold text-xl">
              Repo: {repo.ownerName}/{repo.name}
            </div>
            {showTree && (
              <>
                <select
                  className="border rounded-md text-sm w-1/8"
                  value={searchType}
                  onChange={(e) =>
                    setSearchType(e.target.value as "grep" | "semantic" | "filePath")
                  }
                >
                  <option value="grep">Grep</option>
                  <option value="semantic">Semantic</option>
                  <option value="filePath">File path</option>
                </select>
                <input
                  type="text"
                  className="border rounded-md text-sm w-1/3"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  placeholder="Search"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSearch();
                    }
                  }}
                />
                <button
                  className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded"
                  onClick={handleSearch}
                >
                  Search
                </button>
              </>
            )}
          </div>
        </div>
        <div className="flex justify-between">
          {showTree && (
            <div className="w-1/3 overflow-y-auto h-screen">
              {isLoadingFileTree ? (
                <div>Loading...</div>
              ) : (
                // TODO: use something like https://codesandbox.io/p/sandbox/react-monaco-file-tree-ww9kis
                <JsonView
                  value={fileTree}
                  collapsed={1}
                  shortenTextAfterLength={0}
                  onExpand={(treeNode) => {
                    if ("_file" in treeNode.value && !treeNode.expand) {
                      setFilePath(treeNode.value["_file"]["path"]);
                    }
                  }}
                  displayDataTypes={false}
                  enableClipboard={false}
                />
              )}
            </div>
          )}
          {filePath && rightPane === "file" && (
            <div className={clsx(showTree && "w-2/3")}>
              {isLoadingFileContent ? (
                <div>Loading...</div>
              ) : (
                <div>
                  <div className="font-semibold">
                    <a
                      className="hover:underline"
                      href={`${window.location.origin}/app/admin?repoId=${repoId}&filePath=${filePath}`}
                      target="_blank"
                    >
                      {filePath}
                    </a>
                  </div>
                  {/* {monaco && (
                    <Editor
                      defaultValue={fileContent}
                      height="100vh"
                      width={clsx(showTree ? "66vw" : "100vw")}
                      options={{
                        minimap: { renderCharacters: false, showSlider: "always" },
                        readOnly: true,
                      }}
                      language={fileLanguage}
                    />
                  )} */}
                  <pre>
                    <code className="text-sm">{fileContent}</code>
                  </pre>
                </div>
              )}
            </div>
          )}
          {rightPane === "search" && (
            <div className="w-2/3">
              {isLoadingSearchResults ? (
                <div>Loading...</div>
              ) : (
                <div>
                  <div className="font-semibold text-lg">Search results</div>
                  {map(searchResults, (result) => {
                    return (
                      <div key={result.filePath} className="my-4">
                        <div className="font-semibold">
                          <a
                            className="hover:underline"
                            href={`${window.location.origin}/app/admin?repoId=${repoId}&filePath=${result.filePath}`}
                            target="_blank"
                          >
                            {result.filePath}
                          </a>
                        </div>
                        <pre>
                          <code className="text-sm">{result.fragment}</code>
                        </pre>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
