import { Fragment, useState, useMemo } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon, ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { includes, map, size, some } from "lodash";
import { clsx } from "clsx";
import { useMutation } from "@apollo/client";

import { Button } from "./Button";
import { GetReposQuery, TaskInput, ClientFeatures, TaskType } from "../__generatedGQL__/graphql";
import { useAppContext, useNotificationContext } from "../providers";
import { handleRichTextHtml } from "../utils/richTextUtils";
import { IconTooltip } from "./IconTooltip";
import { ReactQuillEditor } from "./rich-text-editor/RichTextEditor";
import { gql } from "../__generatedGQL__";
import { contactUsMarkdownMessage } from "../utils/clientUtils";

const GET_SIGNED_URL = gql(`
  mutation GetSignedWriteUrl($fileName: String!, $bucketName: String!, $contentType: String) {
    getSignedWriteUrl(fileName: $fileName, bucketName: $bucketName, contentType: $contentType)
  }
`);

interface IProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  createTask: (task: TaskInput) => Promise<{ id: string }>;
  creatingTask: boolean;
  repos: GetReposQuery["repos"];
}

const TASK_TYPES_ORDER = [
  TaskType.UiChange,
  TaskType.UiBug,
  TaskType.Bug,
  TaskType.CopyChange,
  TaskType.VisualImprovement,
  TaskType.Other,
];

export const HUMAN_READABLE_TASK_TYPES = {
  [TaskType.CopyChange]: "Copy change",
  [TaskType.VisualImprovement]: "Styling change",
  [TaskType.UiChange]: "UI change",
  [TaskType.UiBug]: "UI bug",
  [TaskType.Bug]: "Bug",
  [TaskType.Other]: "Other",
};

export const CreateIssueModal = ({ open, setOpen, createTask, creatingTask, repos }: IProps) => {
  const { user, selectedClientId, selectedClient } = useAppContext();
  const { showNotification } = useNotificationContext();
  const [getSignedWriteUrl, { loading: getSignedWriteUrlLoading }] = useMutation(GET_SIGNED_URL);

  const [title, setTitle] = useState("");
  const [repository, setRepository] = useState<number>(size(repos) === 1 ? repos[0].id : null);
  const [description, setDescription] = useState("");
  const [location, setLocation] = useState("");

  const [taskType, setTaskType] = useState<TaskType>(null);

  const [isAdvancedSettingsOpen, setIsAdvancedSettingsOpen] = useState(false);
  const [disablePullRequestCreation, setDisablePullRequestCreation] = useState(false);

  const placeholder = useMemo(() => {
    switch (taskType) {
      case TaskType.CopyChange:
        return "Enter detailed product requirements like the current and expected copy.";
      case TaskType.VisualImprovement:
        return "Enter detailed product requirements like the current and expected visual design.";
      case TaskType.UiChange:
        return "Enter detailed product requirements like the current and expected UI. If this involves using data from the backend, include details about relevant data.";
      case TaskType.UiBug:
        return "Enter detailed product requirements like the steps to reproduce, the expected result, and the actual result.";
      case TaskType.Bug:
        return "Enter detailed requirements like the steps to reproduce, the expected result, and the actual result.";
      case TaskType.Other:
      default:
        return "Enter detailed product requirements like the current and expected behavior. If this involves using data from the backend, include details about relevant data.";
    }
  }, [taskType]);

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6">
                  <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                      onClick={() => setOpen(false)}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:ml-px sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        Create new issue
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Add details to provide Tusk context on the change required.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="mt-4">
                    <label className="block text-sm font-medium text-gray-700">Type</label>
                    <select
                      value={taskType}
                      onChange={(e) => setTaskType(e.target.value as TaskType)}
                      className="mt-2 p-2 w-full border rounded-md text-sm focus:ring-purple-500 focus:border-purple-500"
                    >
                      <option value="">Select a type</option>
                      {map(TASK_TYPES_ORDER, (taskType) => (
                        <option key={taskType} value={taskType}>
                          {HUMAN_READABLE_TASK_TYPES[taskType]}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="mt-4">
                    <label className="block text-sm font-medium text-gray-700">Title</label>
                    <input
                      type="text"
                      className="mt-2 p-2 w-full border rounded-md text-sm focus:ring-purple-500 focus:border-purple-500"
                      placeholder={(() => {
                        switch (taskType) {
                          case TaskType.CopyChange:
                            return "E.g., Change text in first paragraph of Authentication doc";
                          case TaskType.UiBug:
                            return "E.g., Fix counting bug in the table pagination";
                          case TaskType.Bug:
                            return "E.g., Fix 500 error when user tries to create a new issue";
                          case TaskType.VisualImprovement:
                            return "E.g., Make input fields more rounded";
                          default:
                            return "E.g., Add primary button to create new issue";
                        }
                      })()}
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </div>
                  {size(repos) > 1 && (
                    <div className="mt-4">
                      <div className="flex justify-between">
                        <label className="flex items-center text-sm font-medium text-gray-700">
                          Repository
                          <IconTooltip tooltipText="Do not select a repository if you want Tusk to auto-select the right one" />
                        </label>
                        <span className="text-sm leading-6 text-gray-500">Optional</span>
                      </div>
                      <select
                        value={repository}
                        onChange={(e) => setRepository(Number(e.target.value))}
                        className="mt-2 p-2 w-full border rounded-md text-sm focus:ring-purple-500 focus:border-purple-500"
                      >
                        <option value="">Select a repository</option>
                        {map(repos, (repo) => (
                          <option key={repo.id} value={repo.id}>
                            {repo.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}

                  <div className="mt-4">
                    <div className="flex justify-between">
                      <label className="flex items-center text-sm font-medium text-gray-700">
                        Location
                        <IconTooltip tooltipText="Specify where in the app or website this change should be made" />
                      </label>
                      <span className="text-sm leading-6 text-gray-500">Optional</span>
                    </div>
                    <input
                      type="text"
                      className="mt-2 p-2 w-full border rounded-md text-sm focus:ring-purple-500 focus:border-purple-500"
                      placeholder={(() => {
                        switch (taskType) {
                          case TaskType.CopyChange:
                            return "E.g., API documentation page";
                          case TaskType.UiBug:
                            return "E.g., Issues table";
                          case TaskType.Bug:
                            return "E.g., Create issue API";
                          case TaskType.VisualImprovement:
                            return "E.g., 'Create Issue' modal";
                          default:
                            return "E.g., Home dashboard";
                        }
                      })()}
                      value={location}
                      onChange={(e) => setLocation(e.target.value)}
                    />
                  </div>
                  <div className="mt-4">
                    <label className="flex items-center text-sm font-medium text-gray-700">
                      Description
                      <IconTooltip tooltipText="Add detailed product requirements to help Tusk generate the highest-quality code" />
                    </label>
                    <div className="mt-2">
                      <ReactQuillEditor
                        key={taskType}
                        value={description}
                        // modules={modules}
                        onChange={setDescription}
                        placeholder={placeholder}
                      />
                    </div>
                  </div>
                  <div className="mt-4">
                    <div
                      className={clsx(
                        "cursor-pointer",
                        isAdvancedSettingsOpen ? "font-semibold" : "font-normal",
                      )}
                      onClick={() => setIsAdvancedSettingsOpen(!isAdvancedSettingsOpen)}
                    >
                      <h3 className="text-sm font-medium inline-block">
                        Advanced Settings
                        {isAdvancedSettingsOpen ? (
                          <ChevronDownIcon
                            className="ml-1 h-4 w-4 text-gray-400 inline-block"
                            aria-hidden="true"
                          />
                        ) : (
                          <ChevronRightIcon
                            className="ml-1 h-4 w-4 text-gray-400 inline-block"
                            aria-hidden="true"
                          />
                        )}
                      </h3>
                    </div>
                    {isAdvancedSettingsOpen && (
                      <fieldset className="ml-4">
                        <div className="mt-2 relative flex items-start">
                          <div className="flex h-6 items-center">
                            <input
                              id="disablePullRequestCreation"
                              name="disablePullRequestCreation"
                              type="checkbox"
                              className="h-4 w-4 rounded border-gray-300 text-purple-600 focus:ring-purple-600"
                              checked={disablePullRequestCreation}
                              onChange={(e) => setDisablePullRequestCreation(e.target.checked)}
                            />
                          </div>
                          <div className="ml-3 text-sm leading-6">
                            <label
                              htmlFor="disablePullRequestCreation"
                              className="flex items-center font-medium text-gray-700"
                            >
                              Create only branch (no pull request)
                              <IconTooltip tooltipText="Tusk will create a branch with proposed changes" />
                            </label>
                          </div>
                        </div>
                      </fieldset>
                    )}
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <Button
                      size="md"
                      className="ml-3"
                      disabled={!title || !description || !taskType}
                      loading={creatingTask}
                      onClick={async () => {
                        const filePrefix = `task-${user.id}-${selectedClientId}`;
                        const { markdownDescription, imageUrls } = await handleRichTextHtml({
                          htmlContent: description,
                          getSignedWriteUrl,
                          showNotification,
                          filePrefix,
                        });
                        try {
                          const { id: taskId } = await createTask({
                            metadata: {
                              title,
                              location,
                              description: markdownDescription,
                              images: imageUrls.length > 0 ? imageUrls : null,
                              disablePullRequestCreation,
                            },
                            repoId: repository,
                            type: taskType,
                          });
                          showNotification({
                            title: "Issue created successfully 🎉",
                            message: `Tusk will create the ${
                              disablePullRequestCreation ? "branch" : "pull request"
                            } for the issue in about 15 minutes. Check the [activity log](/app/task/${taskId}) for details.`,
                          });
                          setOpen(false);
                          setTitle("");
                          setRepository(size(repos) === 1 ? repos[0].id : null);
                          setDescription("");
                          setLocation("");
                          setTaskType(null);
                          setIsAdvancedSettingsOpen(false);
                          setDisablePullRequestCreation(false);
                        } catch (e) {
                          console.error(e);
                          showNotification({
                            type: "error",
                            title: "Error creating issue",
                            message: `Please try again. If this error persists, ${contactUsMarkdownMessage(
                              selectedClient,
                            )}`,
                          });
                          return;
                        }
                      }}
                    >
                      Create and generate
                    </Button>
                    <Button size="md" variant="secondary" onClick={() => setOpen(false)}>
                      Cancel
                    </Button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};
