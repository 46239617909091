import { ReactNode } from "react";

interface IProps {
  name: string;
  subheader?: ReactNode;
}

export const PageHeading = ({ name, subheader }: IProps) => {
  return (
    <div className="md:flex md:items-center md:justify-between">
      <div className="min-w-0 flex-1 mb-6">
        <h2 className="text-xl font-bold leading-7 text-gray-900 sm:truncate sm:text-2xl sm:tracking-tight">
          {name}
        </h2>
        {subheader}
      </div>
    </div>
  );
};
