interface IProps {
  embedLink: string;
}

export const DemoVideo = ({ embedLink }: IProps) => {
  return (
    <div className="mt-12 container mx-auto px-4 aspect-video max-w-6xl">
      <iframe
        src={embedLink}
        allowFullScreen
        className="h-full w-full rounded-2xl shadow-lg"
      ></iframe>
    </div>
  );
};
