import { Container } from "../components/Container";
// import backgroundImage from "../images/background-faqs.jpg";
import backgroundImage from "../images/background-purple.png";
// import backgroundImage from "../images/background-features.jpg";

const testimonials = [
  {
    body: "We have been using Tusk to squash out minor bugs and polish our UI. Their integration with Linear is great and easily fits into our workflow. We received great support from Marcel and Sohil as well. I highly recommend Tusk!",
    author: {
      name: "Nitin Muthyala",
      title: "Co-founder & CTO at HitPay (YC S21)",
      imageUrl: "https://storage.googleapis.com/use-tusk-dev-static/nitin_muthyala_photo.jpeg",
    },
  },
  {
    body: "Tusk is really good at going through the codebase to figure out what file changes to make and which components to use. It’s able to complete our tasks even when I give it a pretty vague description of where to make the change. I've used a lot of AI coding tools out there but this might be one of the most convenient experiences I've had.",
    author: {
      name: "Yash Goenka",
      title: "Co-founder & CEO at Jarvys.ai",
      imageUrl: "https://storage.googleapis.com/use-tusk-dev-static/yash_goenka_photo.jpeg",
    },
  },
  {
    body: "I’m always seeking new ways to boost engineering productivity. I have vetted several AI solutions promising to free my team from countless hours of mundane work. Tusk was the only product that delivered on this promise.",
    author: {
      name: "Stephen Hutchens",
      title: "Chief Technology Officer at Toolbox (YC S20)",
      imageUrl: "https://storage.googleapis.com/use-tusk-dev-static/stephen_hutchens_photo.jpeg",
    },
  },
  {
    body: "I love that the Jira integration allows me to run Tusk on a ticket with one click. It's allowed me to 'fire and forget' UX fixes and copy changes without using up engineering bandwidth.",
    author: {
      name: "Sakshi Jain",
      title: "Principal Engineer at Kapstan",
      imageUrl: "https://storage.googleapis.com/use-tusk-dev-static/sakshi_jain_photo.jpeg",
    },
  },
  {
    body: "Tusk keeps our engineers focused by handling legal and marketing copy changes. Really enjoy working with their team — they ship fast and are responsive to our needs. Excited for Tusk to provide a head start to our engineers investigating bugs and more complex tickets.",
    author: {
      name: "Kai Anderson",
      title: "Director of Strategic Operations at Wefunder (YC W13)",
      imageUrl: "https://storage.googleapis.com/use-tusk-dev-static/kai_anderson_photo.png",
    },
  },
  {
    body: "Using Tusk lets my engineering team spend more time focused on their most important tasks. I feel empowered to add more tickets to a sprint that traditionally I'd keep in the backlog to avoid bloating up the sprint. This helps us keep our products neat and tidy while maintaining our velocity.",
    author: {
      name: "Anthony Parente",
      title: "Head of Product at Dispatch",
      imageUrl: "https://storage.googleapis.com/use-tusk-dev-static/anthony_parente_photo.jpeg",
    },
  },
];

export const Testimonials = () => {
  return (
    <section
      id="testimonials"
      aria-labelledby="faq-title"
      className="relative overflow-hidden bg-slate-50 py-20 sm:py-32"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      {/* <img
        className="absolute left-1/2 top-1/2 max-w-none translate-x-[-44%] translate-y-[-42%]"
        src={backgroundImage}
        alt=""
        width={2245}
        height={1636}
      /> */}
      <Container className="relative">
        <div>
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-xl text-center">
              <h2 className="text-base font-semibold leading-7 text-purple-100">
                Proven on production codebases
              </h2>
              <p className="mt-2 text-3xl font-display tracking-tight text-white sm:text-4xl">
                Hear what our customers have to say about us
              </p>
            </div>
            <div className="mx-auto mt-16 flow-root max-w-2xl sm:mt-20 lg:mx-0 lg:max-w-none">
              <div className="-mt-8 sm:-mx-4 sm:columns-2 sm:text-[0] lg:columns-3">
                {testimonials.map((testimonial) => (
                  <div
                    key={testimonial.author.title}
                    className="pt-8 sm:inline-block sm:w-full sm:px-4"
                  >
                    <figure className="rounded-2xl bg-gray-50 p-8 text-sm leading-6 shadow-lg shadow-slate-900/30">
                      <blockquote className="text-gray-900">
                        <p>{`${testimonial.body}`}</p>
                      </blockquote>
                      <figcaption className="mt-6 flex items-center gap-x-4">
                        <img
                          className="h-10 w-10 rounded-full bg-gray-50"
                          src={testimonial.author.imageUrl}
                          alt=""
                        />
                        <div>
                          <div className="font-semibold text-gray-900">
                            {testimonial.author.name}
                          </div>
                          <div className="text-gray-600">{`${testimonial.author.title}`}</div>
                        </div>
                      </figcaption>
                    </figure>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};
