import { useState } from "react";
import { RadioGroup } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/20/solid";

const getPriceSuffix = (price, priceSuffix) => {
  return price === "Custom" ? "" : priceSuffix;
};

interface Tier {
  name: string;
  id: string;
  href: string | { monthly: string; annually: string };
  price: { monthly: string; annually: string };
  description: string;
  features: string[];
  mostPopular: boolean;
  ctaText: string;
}

const frequencies = [
  { value: "monthly", label: "Monthly", priceSuffix: "/month" },
  { value: "annually", label: "Annually", priceSuffix: "/year" },
];

const tiers: Tier[] = [
  // {
  //   name: "Free",
  //   id: "tier-free",
  //   href: "https://usetusk.ai/app",
  //   price: { monthly: "$0", annually: "$0" },
  //   description: "For individuals to try Tusk on a codebase or side project with limited usage",
  //   features: [
  //     "3 merged pull requests total",
  //     "1 synced GitHub repository",
  //     "Integration with Jira, Linear, and Notion",
  //     "Basic code generation capabilities",
  //     "Code investigator",
  //   ],
  //   mostPopular: false,
  //   ctaText: "Try now",
  // },
  {
    name: "Basic",
    id: "tier-basic",
    href: {
      monthly: "https://buy.stripe.com/28o8Au0g0086c8w6oJ",
      annually: "https://buy.stripe.com/eVa7wqgeY5sqdcA4gC",
    },
    price: { monthly: "$105", annually: "$1,134" },
    description: "For small teams looking to complete more tickets with less overhead",
    features: [
      "10 merged pull requests per month",
      "2 synced GitHub repositories",
      "Basic code generation capabilities",
      "Unlimited code investigations",
      "Auto-triaging of tickets",
    ],
    mostPopular: false,
    ctaText: "Subscribe now",
  },
  {
    name: "Team",
    id: "tier-team",
    href: {
      monthly: "https://buy.stripe.com/bIY5oi4wgg74fkI00e",
      annually: "https://buy.stripe.com/dR63ga2o8dYW1tS00g",
    },
    price: { monthly: "$495", annually: "$5,346" },
    description: "For high-growth companies to embed Tusk into their development lifecycle",
    features: [
      "100 merged pull requests per month",
      "5 synced GitHub repositories",
      "Everything in Basic plan",
      "Advanced code generation capabilities",
      "Custom AI agent for your codebase",
      "Integration with CI/CD",
      "VIP support, dedicated Slack channel",
    ],
    mostPopular: true,
    ctaText: "Subscribe now",
  },
  {
    name: "Enterprise",
    id: "tier-enterprise",
    href: "https://cal.com/marceltan/demo",
    price: { monthly: "Custom", annually: "Custom" },
    description: "For large companies who require enterprise-grade infrastructure and support",
    features: [
      "200+ merged pull requests per month",
      "10+ synced GitHub repositories",
      "Everything in Team plan",
      "Self-hosting",
      "SAML / SSO",
      "Access to beta features",
      "Dedicated technical consultant",
      "Custom reporting tools",
    ],
    mostPopular: false,
    ctaText: "Speak to founders",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const PricingPlans = () => {
  const [frequency, setFrequency] = useState(frequencies[0]);

  return (
    <div id="pricing" aria-labelledby="pricing-plans" className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-4xl text-center">
          {/* <h2 className="text-base font-semibold leading-7 text-purple-600">Pricing</h2> */}
          <p className="mt-2 text-4xl font-display tracking-tight text-gray-900 sm:text-5xl">
            Pricing plans for all teams
          </p>
        </div>
        <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
          Choose the plan that best suits your team size. Our paid plans provide higher-quality code
          generation, greater usage limits, and VIP support.
        </p>
        <div className="mt-16 flex justify-center">
          <RadioGroup
            value={frequency}
            onChange={setFrequency}
            className="grid grid-cols-2 gap-x-1 rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200"
          >
            <RadioGroup.Label className="sr-only">Payment frequency</RadioGroup.Label>
            {frequencies.map((option) => (
              <RadioGroup.Option
                key={option.value}
                value={option}
                className={({ checked }) =>
                  classNames(
                    checked ? "bg-purple-600 text-white" : "text-gray-500",
                    "cursor-pointer rounded-full px-2.5 py-1",
                  )
                }
              >
                <span>{option.label}</span>
              </RadioGroup.Option>
            ))}
          </RadioGroup>
        </div>
        <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {tiers.map((tier) => (
            <div
              key={tier.id}
              className={classNames(
                tier.mostPopular ? "ring-2 ring-purple-600" : "ring-1 ring-gray-200",
                "rounded-3xl p-8 xl:p-10",
              )}
            >
              <div className="flex items-center justify-between gap-x-4">
                <h3
                  id={tier.id}
                  className={classNames(
                    tier.mostPopular ? "text-purple-600" : "text-gray-900",
                    "text-lg font-semibold leading-8",
                  )}
                >
                  {tier.name}
                </h3>
                {tier.mostPopular ? (
                  <p className="rounded-full bg-purple-600/10 px-2.5 py-1 text-xs font-semibold leading-5 text-purple-600">
                    Most popular
                  </p>
                ) : null}
              </div>
              <p className="mt-4 text-sm leading-6 text-gray-600">{tier.description}</p>
              <p className="mt-6 flex items-baseline gap-x-1">
                <span className="text-3xl font-semibold tracking-tight text-gray-900">
                  {tier.price[frequency.value]}
                </span>
                <span className="text-sm font-semibold leading-6 text-gray-600">
                  {getPriceSuffix(tier.price[frequency.value], frequency.priceSuffix)}
                </span>
              </p>
              <a
                href={typeof tier.href === "string" ? tier.href : tier.href[frequency.value]}
                target={
                  tier.name === "Basic" || tier.name === "Team" || tier.name === "Enterprise"
                    ? "_blank"
                    : "_self"
                }
                rel={
                  tier.name === "Basic" || tier.name === "Team" || tier.name === "Enterprise"
                    ? "noopener noreferrer"
                    : ""
                }
                aria-describedby={tier.id}
                className={classNames(
                  tier.mostPopular
                    ? "bg-purple-600 text-white shadow-sm hover:bg-purple-500"
                    : "text-purple-600 ring-1 ring-inset ring-purple-200 hover:ring-purple-300",
                  "mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-600",
                )}
              >
                {tier.ctaText}
              </a>
              <ul role="list" className="mt-8 space-y-3 text-sm leading-6 text-gray-600 xl:mt-10">
                {tier.features.map((feature) => (
                  <li key={feature} className="flex gap-x-3">
                    <CheckIcon className="h-6 w-5 flex-none text-purple-600" aria-hidden="true" />
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
