import { GetUserQuery } from "../__generatedGQL__/graphql";

export const getSlackChannelUrl = (channelId: string) => {
  return `https://slack.com/app_redirect?channel=${channelId}`;
};

export const contactUsMarkdownMessage = (client: GetUserQuery["user"]["clients"][0]) => {
  return `please ${
    client.customerSlackChannelId
      ? "[let us know](https://slack.com/app_redirect?channel=${selectedClient.customerSlackChannelId})"
      : "contact us at support@usetusk.ai"
  } and we'll figure this out right away.`;
};
