import { CloudArrowUpIcon, LockClosedIcon, ServerIcon } from "@heroicons/react/20/solid";

export const SecurityPolicy = () => {
  return (
    <section id="privacy">
      <div className="max-w-4xl mx-auto p-6 text-gray-900 mb-12">
        <h1 className="text-4xl font-bold text-gray-900 mb-4 text-center">Tusk Privacy Policy</h1>
        <p className="text-sm text-gray-500 mb-9 text-center">Last Updated: October 17, 2024</p>

        {/* Privacy Policy Overview */}
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-3">Privacy Policy Overview</h2>
          <p className="mb-4">
            This Privacy Policy describes how Tusk AI, Inc. ("we", "us", "our", “Tusk”) collects,
            uses, and discloses your Personal Information when you use our application Tusk ("the
            App"). By using the App, you agree to the collection and use of information in
            accordance with this policy.
          </p>
          <p>
            At Tusk, we are deeply committed to the privacy of your data. This Privacy Policy
            outlines our rigorous practices and protocols designed to protect your information,
            emphasizing our dedication to privacy, security, and compliance with industry standards.
            Our objective is to maintain a secure and trustworthy environment for all our users.
          </p>
        </section>

        {/* Managed Services Vendors and Compliance */}
        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-3">
            Managed Services Vendors and Compliance
          </h2>
          <p className="mb-4">
            Tusk leverages a suite of top-tier managed services vendors, each complying with
            stringent security standards, including SOC 2. Our choice of vendors reflects our
            unwavering commitment to security:
          </p>

          <ul className="list-disc pl-5 space-y-2 mb-4">
            <li>
              <span className="font-semibold">Auth0:</span> Ensures robust authentication processes.
            </li>
            <li className="ml-5">
              <a href="https://auth0.com/security" className="text-blue-600 hover:text-blue-800">
                Security Information
              </a>
            </li>
            <li>
              <span className="font-semibold">Google Cloud Platform:</span> Hosts core services such
              as Cloud SQL, Cloud Storage, and App Engine, alongside secure logging capabilities.
            </li>
            <li className="ml-5">
              <a
                href="https://cloud.google.com/security"
                className="text-blue-600 hover:text-blue-800"
              >
                Security Information
              </a>
            </li>
            <li>
              <span className="font-semibold">OpenAI, Anthropic & Gemini:</span> Power our AI models
              for code generation. All three organizations have strict policies ensuring data
              security and privacy, and do not train their models on your data.
            </li>
            <li className="ml-5">
              <a href="https://openai.com/security" className="text-blue-600 hover:text-blue-800">
                OpenAI Security Information
              </a>
            </li>
            <li className="ml-5">
              <a href="https://trust.anthropic.com/" className="text-blue-600 hover:text-blue-800">
                Anthropic Security Information
              </a>
            </li>
            <li className="ml-5">
              <a
                href="https://ai.google.dev/gemini-api/terms#data-use-paid"
                className="text-blue-600 hover:text-blue-800"
              >
                Gemini Security Information
              </a>
            </li>
            <li>
              <span className="font-semibold">GitHub:</span> Provides a secure platform for managing
              commits and pull requests.
            </li>
            <li className="ml-5">
              <a href="https://github.com/security" className="text-blue-600 hover:text-blue-800">
                Security Information
              </a>
            </li>
            <li>
              <span className="font-semibold">Pinecone:</span> Aids in advanced, secure data
              processing.
            </li>
            <li className="ml-5">
              <a
                href="https://www.pinecone.io/security/"
                className="text-blue-600 hover:text-blue-800"
              >
                Security Information
              </a>
            </li>
            <li>
              <span className="font-semibold">PostHog:</span> Tracks product analytics and user
              sessions.
            </li>
            <li className="ml-5">
              <a
                href="https://posthog.com/handbook/company/security"
                className="text-blue-600 hover:text-blue-800"
              >
                Security Information
              </a>
            </li>
            <li>
              <span className="font-semibold">Sentry:</span> Monitors for errors and performance
              issues.
            </li>
            <li className="ml-5">
              <a href="https://sentry.io/security/" className="text-blue-600 hover:text-blue-800">
                Security Information
              </a>
            </li>
            <li>
              <span className="font-semibold">Helicone:</span> Logs LLM requests for performance
              monitoring.
            </li>
            <li className="ml-5">
              <a
                href="https://docs.helicone.ai/references/data-autonomy"
                className="text-blue-600 hover:text-blue-800"
              >
                Security Information
              </a>
            </li>
          </ul>

          <p>
            These vendors are integral to safeguarding the integrity and security of our services,
            ensuring that your data is always in safe hands.
          </p>
        </section>

        {/* Data Collection and Use */}
        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-3">Data Collection and Use</h2>
          <p className="mb-4">
            While using our App, we may collect information related to your codebase, commits,
            GitHub tickets, pull requests (PRs), and PR comments. This data is essential to
            understand your interaction with our App and to continually improve our services.
            Specifics include:
          </p>

          <ul className="list-disc pl-5 space-y-2 mb-4">
            <li>
              <span className="font-semibold">Code Storage:</span> The code from GitHub is fetched
              at runtime and is not permanently stored on our servers. Instead, we store
              non-readable embeddings of the codebase ensuring data privacy.
            </li>
            <li>
              <span className="font-semibold">AI Models and Data Handling:</span> Our AI models,
              powered by OpenAI and Anthropic, are used for code generation. These providers do not
              use this data for training and retain it for only{" "}
              <span className="font-semibold">30 days</span>.
            </li>
            <li>
              <span className="font-semibold">Data Ownership:</span> You retain full ownership of
              all data provided to and generated by the App. We use this data solely to power our
              products and enhance its functionality. Your data can be securely deleted upon request
              or post-subscription termination.
            </li>
          </ul>

          <p className="mb-4">The collected data is used for:</p>
          <ul className="list-disc pl-5 space-y-2">
            <li>Automatically generating and modifying PRs.</li>
            <li>Providing valuable insights to improve the App.</li>
            <li>Monitoring App usage and addressing technical issues.</li>
          </ul>
        </section>

        {/* GitHub Access and Data Storage */}
        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-3">
            GitHub Access and Data Storage
          </h2>
          <p className="mb-4">
            To enable seamless operation and integration with your development workflow, Tusk
            requires specific access to GitHub repositories. Here's an overview of how we handle
            this access:
          </p>

          <ul className="list-disc pl-5 space-y-2 mb-4">
            <li>
              <span className="font-semibold">Read and Write Access:</span> We have read access for
              gathering necessary data related to commits, pull requests (PRs), and other repository
              activities. Write access is utilized to facilitate the creation of pull requests,
              including file creation, branch creation, and commit generation.
            </li>
            <li>
              <span className="font-semibold">Data Handling:</span> Your files are fetched at
              runtime, and we do not store any files permanently on our servers. We prioritize the
              integrity and confidentiality of your data at every step.
            </li>
            <li>
              <span className="font-semibold">Branch Protection Compliance:</span> Tusk fully
              respects and complies with GitHub's branch protection rules, ensuring that your code's
              security and workflow integrity are maintained:
            </li>
            <ul className="list-disc ml-5 space-y-2">
              <li>
                <span className="font-semibold">No Direct Pushes to Protected Branches:</span> Tusk
                cannot directly push changes to your protected branches. Any modifications proposed
                by Tusk are submitted through the standard pull request process.
              </li>
              <li>
                <span className="font-semibold">Adherence to Review Processes:</span> All changes
                made by Tusk undergo your team's established pull request review process, upholding
                your project's governance, coding standards, and quality checks.
              </li>
              <li>
                <span className="font-semibold">
                  No Access to Modify Branch Protection Settings:
                </span>{" "}
                Tusk does not have the capability to modify or update your branch protection
                settings. This ensures that your repository's security configurations remain under
                your team's exclusive control.
              </li>
            </ul>
            <li>
              <span className="font-semibold">Secure Authentication:</span> We use GitHub's
              recommended authentication method, ensuring secure and controlled access to your
              repositories. The necessary installation ID is stored securely, and we maintain
              detailed logs of all actions taken, which users can request to review.
            </li>
          </ul>
        </section>

        {/* Security Best Practices */}
        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-3">Privacy Best Practices</h2>
          <p className="mb-4">
            Our dedication to securing customer data is evident in our adoption of industry-leading
            security practices:
          </p>

          <ul className="list-disc pl-5 space-y-2 mb-4">
            <li>
              <span className="font-semibold">Secrets Management:</span> Google Cloud Secret Manager
              is employed for robust secrets management.
            </li>
            <li>
              <span className="font-semibold">Network Segmentation:</span> Separating web servers
              and databases enhances overall security.
            </li>
            <li>
              <span className="font-semibold">Data Retention:</span> Customer data can be securely
              deleted upon request or post-subscription termination.
            </li>
            <li>
              <span className="font-semibold">Data Encryption:</span> All REST API transmissions are
              HTTPS-protected, and we use TLS for data encryption.
            </li>
            <li>
              <span className="font-semibold">Cloud & Managed Infrastructure:</span> Leveraging
              Google Cloud’s infrastructure ensures robust data security.
            </li>
            <li>
              <span className="font-semibold">Access Control:</span> Stringent access controls
              restrict data access to authorized personnel only.
            </li>
            <li>
              <span className="font-semibold">Real-time Surveillance:</span> Continuous monitoring
              ensures immediate response to potential security threats.
            </li>
            <li>
              <span className="font-semibold">Comprehensive Logging:</span> Detailed API call logs
              facilitate effective security analysis and auditing.
            </li>
            <li>
              <span className="font-semibold">Multi-Tenant Architecture:</span> Ensures logical
              segregation and isolation of customer data.
            </li>
            <li>
              <span className="font-semibold">Third-Party Security Compliance:</span> We partner
              with third-party services that meet our high standards for security and privacy.
            </li>
            <li>
              <span className="font-semibold">Data Privacy:</span> We are committed to protecting
              customer data privacy and do not sell or share data for marketing purposes.
            </li>
            <li>
              <span className="font-semibold">Authentication Standards:</span> We utilize OAuth 2.0
              for secure user authentication.
            </li>
          </ul>
        </section>

        {/* Incident Response Plan */}
        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-3">Incident Response Plan</h2>
          <p className="mb-4">
            At Tusk, we are prepared to swiftly and effectively address any security incidents to
            minimize impact and protect our users' data:
          </p>

          <ul className="list-disc pl-5 space-y-2 mb-4">
            <li>
              <span className="font-semibold">Detection and Identification:</span> Our systems are
              monitored continuously to detect and identify any potential security incidents
              promptly.
            </li>
            <li>
              <span className="font-semibold">Response Team:</span> We have a dedicated incident
              response team that is trained and ready to respond to security incidents.
            </li>
            <li>
              <span className="font-semibold">Containment and Eradication:</span> Upon detection of
              an incident, immediate steps are taken to contain and eradicate the threat, preventing
              further damage.
            </li>
            <li>
              <span className="font-semibold">Recovery:</span> We implement recovery procedures to
              restore any affected services or data to full functionality.
            </li>
            <li>
              <span className="font-semibold">Notification:</span> In the event of a significant
              breach, affected users will be notified promptly, in compliance with relevant laws and
              regulations.
            </li>
            <li>
              <span className="font-semibold">Post-Incident Analysis:</span> After an incident, we
              conduct a thorough analysis to identify causes, learn from the event, and implement
              improvements to prevent future occurrences.
            </li>
          </ul>

          <p className="mb-4">
            Our Incident Response Plan is reviewed and updated regularly to ensure its effectiveness
            in the face of evolving security threats.
          </p>
        </section>

        {/* User Control and Privacy */}
        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-3">User Control and Privacy</h2>
          <p className="mb-4">User autonomy over their data is a cornerstone of our policy:</p>

          <ul className="list-disc pl-5 space-y-2 mb-4">
            <li>
              <span className="font-semibold">Account Deletion:</span> Users can delete their
              accounts at any time, which leads to the complete deletion of their data from our
              servers and third-party services.
            </li>
            <li>
              <span className="font-semibold">Data Export:</span> Users can request a comprehensive
              data export at any time.
            </li>
            <li>
              <span className="font-semibold">Email addresses:</span> If you connect a third-party
              ticketing service (e.g. Jira, Linear, etc.) we may fetch email addresses of users
              assigned to issues in order to sync them to GitHub users. We do not store this data
              and fetch it at runtime as needed.
            </li>
          </ul>
        </section>

        {/* Communication and Policy Updates */}
        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-3">
            Communication and Policy Updates
          </h2>
          <p className="mb-4">
            Our commitment to transparency extends to how we communicate policy changes:
          </p>
          <ul className="list-disc pl-5 space-y-2 mb-4">
            <li>
              <span className="font-semibold">Updates Notification:</span> Any changes to our
              Privacy Policy will be posted on our website. We advise users to review the policy
              periodically for updates.
            </li>
            <li>
              <span className="font-semibold">Effective Dates:</span> Changes are effective upon
              posting on our website.
            </li>
          </ul>
        </section>

        {/* User Feedback and Concerns */}
        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-3">User Feedback and Concerns</h2>
          <p className="mb-4">
            We at Tusk value our users' feedback and are committed to addressing any security
            concerns they may have:
          </p>
          <ul className="list-disc pl-5 space-y-2 mb-4">
            <li>
              <span className="font-semibold">Open Communication Channels:</span> Users are
              encouraged to report any security concerns or vulnerabilities they may encounter
              through our dedicated support channel at{" "}
              <a href="mailto:security@usetusk.ai" className="text-blue-600 hover:text-blue-800">
                security@usetusk.ai
              </a>
              .
            </li>
            <li>
              <span className="font-semibold">Feedback Review:</span> All feedback and concerns are
              reviewed by our security team and are used to guide improvements in our security
              practices.
            </li>
            <li>
              <span className="font-semibold">User Collaboration:</span> We believe in collaborating
              with our user community to enhance the security of our platform. Suggestions and
              feedback are not only welcomed but are an integral part of our security strategy.
            </li>
            <li>
              <span className="font-semibold">Transparency in Updates:</span> When user feedback
              leads to changes or updates in our security practices or policy, we communicate these
              changes to all users to maintain transparency and trust.
            </li>
          </ul>
          <p className="mb-4">
            Your voice is essential to us, and we are committed to ensuring that your experience
            with Tusk is secure, reliable, and responsive to your needs.
          </p>
        </section>

        {/* Contact Information */}
        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-3">Contact Information</h2>
          <p className="mb-4">
            For any inquiries or concerns regarding our Privacy Policy, please contact us at{" "}
            <a href="mailto:security@usetusk.ai" className="text-blue-600 hover:text-blue-800">
              security@usetusk.ai
            </a>
            .
          </p>
        </section>
      </div>
    </section>
  );
};
