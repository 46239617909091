import { Route, Routes } from "react-router-dom";

import { AppSidebar, ErrorPage } from "../components";
import { AppProvider, NotificationProvider, useAppContext } from "../providers";

import { SettingsPage } from "./SettingsPage";
import { DashboardPage } from "./DashboardPage";
import { AdminPage } from "./AdminPage";
import { AutoTriagePage } from "./AutoTriagePage";

export const AppPage = () => {
  return (
    <div>
      <AppProvider>
        <Routes>
          {/** Refactor so admin has it's own login */}
          <Route path="/admin/*" element={<AdminPage />} />
          <Route
            path="/*"
            element={
              <AppSidebar>
                <NotificationProvider>
                  <Routes>
                    <Route path="/suggested-issues" element={<AutoTriagePage />} />
                    <Route path="/settings/*" element={<SettingsPage />} />
                    <Route path="/task/:taskId" element={<DashboardPage />} />
                    <Route path="/" element={<DashboardPage />} />
                    <Route path="*" element={<ErrorPage />} />
                  </Routes>
                </NotificationProvider>
              </AppSidebar>
            }
          />
        </Routes>
      </AppProvider>
    </div>
  );
};
