import { ReactNode, useState } from "react";
import { Transition } from "@headlessui/react";
import { clsx } from "clsx";

interface TooltipWrapperProps {
  children: ReactNode;
  tooltipText?: string;
  className?: string;
  // direction?: "left" | "right" | "top" | "bottom";
}

export const TooltipWrapper = ({
  children,
  tooltipText,
  className,
  // direction = "right", // by default tooltip shows to the right
}: TooltipWrapperProps) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  return (
    <div
      className={`relative inline-block ${className}`}
      onMouseEnter={() => setIsTooltipVisible(true)}
      onMouseLeave={() => setIsTooltipVisible(false)}
    >
      {children}
      {tooltipText && (
        <Transition
          show={isTooltipVisible}
          // Commenting these out because it causes the tooltip to be partially covered by other components while it is transitioned in
          // enter="transition-opacity duration-100"
          // leave="transition-opacity duration-75"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className={clsx(
              "absolute z-10 w-60 top-1/2 left-full transform -translate-y-1/2 ml-2 bg-gray-900 text-white text-xs font-normal rounded py-1 px-2",
              className,
            )}
          >
            {tooltipText}
          </div>
        </Transition>
      )}
    </div>
  );
};
