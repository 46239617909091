import React from "react";

interface CaseStudyProps {
  backgroundImageUrl: string;
  logoComponent: React.ReactNode;
  quote: string;
  authorName: string;
  authorTitle: string;
  caseStudyLink: string;
  className?: string;
}

export const CaseStudy: React.FC<CaseStudyProps> = ({
  backgroundImageUrl,
  logoComponent,
  quote,
  authorName,
  authorTitle,
  caseStudyLink,
  className,
}) => {
  return (
    <div id="customers" aria-labelledby="customers" className={className}>
      <div className="bg-white">
        <div className="mx-auto max-w-7xl">
          <div className="relative overflow-hidden bg-gray-900 px-6 py-20 shadow-xl sm:rounded-3xl sm:px-10 sm:py-24 md:px-12 lg:px-20">
            <img
              className="absolute inset-0 h-full w-full object-cover brightness-150 saturate-0"
              src={backgroundImageUrl}
              alt=""
            />
            <div className="absolute inset-0 bg-gray-900/90 mix-blend-multiply" />
            <div className="absolute -left-80 -top-56 transform-gpu blur-3xl" aria-hidden="true">
              <div
                className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-r from-[#ff4694] to-[#776fff] opacity-[0.45]"
                style={{
                  clipPath:
                    "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
                }}
              />
            </div>
            <div
              className="hidden md:absolute md:bottom-16 md:left-[50rem] md:block md:transform-gpu md:blur-3xl"
              aria-hidden="true"
            >
              <div
                className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-r from-[#ff4694] to-[#776fff] opacity-25"
                style={{
                  clipPath:
                    "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
                }}
              />
            </div>
            <div className="relative mx-auto max-w-2xl lg:mx-0">
              {logoComponent}
              <figure>
                <blockquote className="mt-6 text-lg font-semibold text-white sm:text-xl sm:leading-8">
                  <p>"{quote}"</p>
                </blockquote>
                <figcaption className="mt-6 text-base text-white">
                  <div className="font-medium text-lg">{authorName}</div>
                  <div className="font-medium text-lg">{authorTitle}</div>
                </figcaption>
                <div className="mt-4">
                  <a
                    href={caseStudyLink}
                    className="text-lg font-semibold text-white hover:underline"
                  >
                    Read customer story <span aria-hidden="true">&rarr;</span>
                  </a>
                </div>
              </figure>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
