/**
 * @param pullRequestUrl
 *   The URL of the pull request
 *   assumes format: https://github.com/{repoOwner}/{repoName}/pull/{pullRequestId})
 * @returns Outputs the format: {repoOwner}/{repoName}#{pullRequestId}
 */
export const getPullRequestIdWithRepo = (pullRequestUrl: string): string => {
  const url = new URL(pullRequestUrl);
  const pathSegments = url.pathname.split("/");
  const repoOwner = pathSegments[1];
  const repoName = pathSegments[2];
  const pullRequestId = pathSegments[4];
  return `${repoOwner}/${repoName}#${pullRequestId}`;
};
