import { Transition } from "@headlessui/react";

export const AvatarButtonGroup = ({ isVisible, buttons = [], isSidebarCollapsed }) => {
  return (
    <Transition
      show={isVisible}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <div className="avatar-button-group absolute right-0 z-10 mt-2 w-48 lg:left-0 lg:bottom-full lg:ml-4 lg:w-64 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
        {buttons.map((button, index) => (
          <button
            key={index}
            onClick={button.onClick}
            className="flex items-center w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
          >
            {button.icon && <button.icon className="h-5 w-5 mr-2" />}
            {button.text}
          </button>
        ))}
      </div>
    </Transition>
  );
};
