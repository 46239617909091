import { HashLink } from "react-router-hash-link";

export function NavLink({
  href,
  onClick,
  children,
  target,
  rel,
}: {
  href?: string;
  onClick?: () => void;
  children: React.ReactNode;
  target?: string;
  rel?: string;
}) {
  return (
    <HashLink
      onClick={onClick}
      to={href}
      className="inline-block rounded-lg px-2 py-1 text-sm text-slate-700 hover:bg-slate-100 hover:text-slate-900"
      smooth
      target={target}
      rel={rel}
    >
      {children}
    </HashLink>
  );
}
