import { useState, useEffect, useMemo, createElement } from "react";
import { get, includes, filter } from "lodash";
import { PencilIcon, NewspaperIcon } from "@heroicons/react/20/solid";
import { clsx } from "clsx";
import { formatDistanceToNow } from "date-fns";

import { AnimatedEllipsis } from "./AnimatedEllipsis";
import { IAgentLog } from "./TaskAgentLog";
import { CustomMarkdown } from "./Markdown";

export interface IToolConfig {
  humanReadableName: string;
  icon: React.ElementType;
  color?: "success" | "error" | "warning";
  isBeta?: boolean;
}

interface IProps {
  isRunning: boolean;
  agentLogs: IAgentLog[];
  toolMap: {
    [toolName: string]: IToolConfig;
  };
  filterAgentLogs?: (agentLogs: IAgentLog[]) => IAgentLog[];
  isLatestRun?: boolean;
}

export const AgentLogsList = ({
  agentLogs,
  toolMap,
  isRunning,
  filterAgentLogs,
  isLatestRun,
}: IProps) => {
  const [visibleAgentLogDescriptions, setVisibleAgentLogDescriptions] = useState<number[]>([]);

  const updatedAgentLogs = useMemo(() => {
    const filteredAgentLogs = filterAgentLogs ? filterAgentLogs(agentLogs) : agentLogs;
    if (isRunning) {
      return [
        ...filteredAgentLogs,
        {
          timestamp: Date.now(),
          type: "tool",
          toolName: "thinking",
          thought: undefined,
          observation: undefined,
          nestedLogs: undefined,
        },
      ];
    }
    return filteredAgentLogs;
  }, [agentLogs, isRunning, filterAgentLogs]);

  useEffect(() => {
    if (isLatestRun && !isRunning && agentLogs.length > 0) {
      const latestLogTimestamp = agentLogs[agentLogs.length - 1].timestamp;
      setVisibleAgentLogDescriptions((prev) => [...prev, latestLogTimestamp]);
    }
  }, [isLatestRun, isRunning, agentLogs]);

  return (
    <ul role="list" className="mt-6 -mb-8">
      {updatedAgentLogs.map((agentLog, agentLogIdx) => {
        let Icon: React.ElementType;
        let title: string;
        let description: string;
        let color: "error" | "warning" | "success" | undefined;

        if (agentLog.type === "tool") {
          Icon = get(toolMap, agentLog.toolName)?.icon || PencilIcon;
          title = get(toolMap, agentLog.toolName)?.humanReadableName;
          description = agentLog.thought;
          color = get(toolMap, agentLog.toolName)?.color;
        } else if (agentLog.type === "observation") {
          Icon = NewspaperIcon;
          title = "Observation";
          description = agentLog.observation;
        }

        if (!title) {
          return null;
        }

        return (
          <li key={agentLog.timestamp}>
            <div className="relative pb-6">
              {agentLogIdx !== updatedAgentLogs.length - 1 ? (
                <span
                  className="absolute left-5 top-5 -ml-px h-full w-0.5 bg-gray-200"
                  aria-hidden="true"
                />
              ) : null}
              <div className="relative flex items-start space-x-3">
                <>
                  <div>
                    <div className="relative px-1">
                      <div
                        className={clsx(
                          "flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white cursor-pointer hover:bg-gray-200",
                          color === "success" && "bg-emerald-100 hover:bg-emerald-200",
                          color === "error" && "bg-red-100 hover:bg-red-200",
                          color === "warning" && "bg-yellow-100 hover:bg-yellow-200",
                        )}
                        onClick={() =>
                          description &&
                          setVisibleAgentLogDescriptions((prev) => {
                            if (isRunning) {
                              return includes(prev, agentLog.timestamp)
                                ? prev
                                : [...prev, agentLog.timestamp];
                            } else {
                              return includes(prev, agentLog.timestamp)
                                ? filter(prev, (ts) => ts !== agentLog.timestamp)
                                : [agentLog.timestamp];
                            }
                          })
                        }
                      >
                        {createElement(Icon, {
                          className: clsx(
                            "h-5 w-5 text-gray-500",
                            color === "success" && "text-emerald-600",
                            color === "warning" && "text-yellow-600",
                            color === "error" && "text-red-600",
                          ),
                          "aria-hidden": true,
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="min-w-0 flex-1 py-1.5">
                    <div
                      className={clsx(
                        "text-sm text-gray-500 hover:text-gray-700",
                        includes(visibleAgentLogDescriptions, agentLog.timestamp)
                          ? "font-semibold"
                          : "font-normal",
                        description ? "cursor-pointer" : "",
                      )}
                      onClick={() =>
                        description &&
                        setVisibleAgentLogDescriptions(
                          includes(visibleAgentLogDescriptions, agentLog.timestamp)
                            ? filter(visibleAgentLogDescriptions, (ts) => ts !== agentLog.timestamp)
                            : [...visibleAgentLogDescriptions, agentLog.timestamp],
                        )
                      }
                    >
                      <span>{title}</span>
                      {agentLog.toolName !== "thinking" && (
                        <span
                          className="ml-2 text-xs text-gray-400 hover:text-gray-600"
                          title={new Date(agentLog.timestamp).toLocaleString()}
                        >
                          {formatDistanceToNow(new Date(agentLog.timestamp), { addSuffix: true })}
                        </span>
                      )}
                      {get(toolMap, `${agentLog.toolName}.isBeta`, false) && (
                        <span className="ml-2 px-2 py-0.5 text-xs font-medium text-purple-700 bg-purple-100 rounded-full">
                          Beta
                        </span>
                      )}
                      {agentLog.toolName === "thinking" && <AnimatedEllipsis />}
                    </div>
                    {includes(visibleAgentLogDescriptions, agentLog.timestamp) && (
                      <>
                        {description && (
                          <div className="mt-2 prose max-w-none prose-headings:text-sm text-sm">
                            <CustomMarkdown>{description}</CustomMarkdown>
                          </div>
                        )}
                        {agentLog.nestedLogs && (
                          <AgentLogsList
                            filterAgentLogs={filterAgentLogs}
                            agentLogs={agentLog.nestedLogs}
                            toolMap={toolMap}
                            isRunning={isRunning}
                            isLatestRun={false}
                          />
                        )}
                      </>
                    )}
                  </div>
                </>
              </div>
            </div>
          </li>
        );
      })}
    </ul>
  );
};
