import { useState, ReactElement } from "react";
import { Transition } from "@headlessui/react";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import { clsx } from "clsx";

interface IconTooltipProps {
  icon?: React.ElementType;
  tooltipText?: string;
  iconClassName?: string;
  tooltipClassName?: string;
  onClick?: () => void;
  placement?: "left" | "right" | "top" | "bottom";
}

const getTooltipPositionClasses = (placement: "left" | "right" | "top" | "bottom") => {
  switch (placement) {
    case "left":
      return "right-full top-1/2 transform -translate-y-1/2 mr-2";
    case "right":
      return "left-full top-1/2 transform -translate-y-1/2 ml-2";
    case "top":
      return "bottom-full left-1/2 transform -translate-x-1/2 mb-2";
    case "bottom":
      return "top-full left-1/2 transform -translate-x-1/2 mt-2";
    default:
      return "left-full top-1/2 transform -translate-y-1/2 ml-2";
  }
};

export const IconTooltip = ({
  icon,
  tooltipText,
  iconClassName,
  tooltipClassName,
  placement = "right",
  onClick,
}: IconTooltipProps) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const IconComponent = icon ? icon : QuestionMarkCircleIcon;

  return (
    <span className="relative flex items-center ml-1">
      <button
        onMouseEnter={() => setIsTooltipVisible(true)}
        onMouseLeave={() => setIsTooltipVisible(false)}
        onClick={onClick}
        // className="cursor-pointer"
      >
        <IconComponent
          className={clsx("h-5 w-5 text-gray-500", iconClassName)}
          aria-hidden="true"
        />
      </button>
      <Transition
        show={Boolean(tooltipText && isTooltipVisible)}
        enter="transition-opacity ease-linear duration-100"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity ease-linear duration-75"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div
          className={clsx(
            "absolute z-10 w-80 bg-gray-900 text-white text-xs font-normal rounded py-1 px-2",
            getTooltipPositionClasses(placement),
            tooltipClassName,
          )}
        >
          {tooltipText}
        </div>
      </Transition>
    </span>
  );
};
