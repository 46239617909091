import { Fragment, useState, useEffect, useCallback, useRef, useLayoutEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Button } from "./Button";
import { trim } from "lodash";
import { getNumberWithOrdinal } from "../utils/textUtils";

interface RunNotesModalProps {
  isOpen: boolean;
  onClose: () => void;
  currentNote?: string | null;
  onUpdateNote: (note: string) => Promise<void>;
  gprrAttempt: number;
  acrrAttempt?: number;
}

export const RunNotesModal: React.FC<RunNotesModalProps> = ({
  isOpen,
  onClose,
  currentNote,
  onUpdateNote,
  gprrAttempt,
  acrrAttempt,
}) => {
  const [note, setNote] = useState(currentNote || "");
  const [isSaving, setIsSaving] = useState(false);

  const textareaRef = useRef(null);

  const handleSave = useCallback(async () => {
    setIsSaving(true);
    await onUpdateNote(note);
    setIsSaving(false);
  }, [note, onUpdateNote]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.code === "Enter") {
        event.preventDefault();
        handleSave();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleSave]);

  const focusTextarea = () => {
    if (textareaRef.current) {
      textareaRef.current.setSelectionRange(
        textareaRef.current.value.length,
        textareaRef.current.value.length,
      );
      textareaRef.current.focus();
    }
  };

  // need this when modal first opens
  useLayoutEffect(() => {
    if (isOpen) {
      focusTextarea();
    }
  }, [isOpen]);

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              // need this for subseqent opens
              afterEnter={focusTextarea}
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white py-4 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                    onClick={onClose}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:ml-px sm:mt-0 sm:text-left w-full">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      Notes for {getNumberWithOrdinal(gprrAttempt)} attempt
                      {acrrAttempt
                        ? ` after the ${getNumberWithOrdinal(acrrAttempt)} review`
                        : " before any reviews"}
                    </Dialog.Title>
                    <div className="mt-3">
                      <textarea
                        ref={textareaRef}
                        className="mt-2 p-2 w-full border rounded-md text-sm focus:ring-purple-500 focus:border-purple-500"
                        rows={4}
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                        placeholder={`Add notes about this Tusk run (what works, what doesn't, etc.)`}
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:justify-end">
                  <Button size="md" variant="secondary" onClick={onClose} className="mr-3">
                    Cancel
                  </Button>
                  <Button
                    size="md"
                    loading={isSaving}
                    onClick={handleSave}
                    disabled={trim(note) === trim(currentNote)}
                  >
                    Save
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
