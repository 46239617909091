import { Button } from "../components";

interface IProps {
  onConnect: () => void;
}

export const ConnectGithub = ({ onConnect }: IProps) => {
  return (
    <div className="flex flex-1 justify-center items-center py-20 ml-7 rounded-lg border border-gray-300">
      <div className="flex flex-col max-w-sm p-8 text-center">
        <img
          className="mx-auto h-16 w-16 flex-shrink-0 rounded-full"
          src="https://asset.brandfetch.io/idZAyF9rlg/idw3q8jby5.png"
          alt="GitHub Logo"
        />
        <h3 className="mt-1 text-sm font-medium text-gray-900">GitHub</h3>
        <dl className="mt-1 flex flex-grow flex-col justify-between">
          <dt className="sr-only">Title</dt>
          <dd className="text-sm text-gray-500">
            Allow Tusk access to create pull requests for your repositories.
          </dd>
          <dt className="sr-only">Role</dt>
          <dd className="mt-3">
            <Button onClick={onConnect} size="lg">
              Connect
            </Button>
          </dd>
        </dl>
      </div>
    </div>
  );
};
