// import { useAuth0 } from "@auth0/auth0-react";
import { DemoVideo } from "./DemoVideo";
import { MovingBorderButton } from "./MovingBorderButton";
import { ProductHuntSocialProofWeek } from "./ProductHuntSocialProofWeek";
import { InfiniteMovingCards } from "./InfiniteMovingCards";
import {
  HitpaySvg,
  WefunderSvg,
  GenieSvg,
  CanixSvg,
  BounceSvg,
  ReploSvg,
  MeowSvg,
  PrefixLogoSvg,
  FundwellSvg,
} from "./CompanyLogos";
import { useEffect } from "react";
import { getCalApi } from "@calcom/embed-react";

const YCSvg = ({ className }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      id="Layer_1"
      viewBox="0 0 1604 320"
      className={className}
    >
      <style>{".st2{fill-rule:evenodd;clip-rule:evenodd;fill:#231f20}"}</style>
      <path
        id="Fill-1"
        d="M0 320h320V0H0z"
        style={{
          fillRule: "evenodd",
          clipRule: "evenodd",
          fill: "#fb651e",
        }}
      />
      <path
        id="Fill-2"
        d="M173 175.9V247h-27v-71.1L77.1 73H110l49.6 76L209 73h32.9z"
        style={{
          fillRule: "evenodd",
          clipRule: "evenodd",
          fill: "#fff",
        }}
      />
      <g id="Group-18" transform="translate(425.854 73.217)">
        <path
          id="Fill-3"
          d="M81.8 34.1C52.2 34.1 30 57 30 86.6s22.2 52.7 51.8 52.7c19 0 35.5-9.8 44.4-26.3l25.4 15.1c-14.2 24.3-40.8 40.1-69.8 40.1C36.2 168.1 0 131.9 0 86.6 0 41.2 36.2 5 81.8 5c29.3 0 55.6 15.6 69.8 40.1l-25.4 15.1c-9-16.5-25.4-26.1-44.4-26.1"
          className="st2"
        />
        <path
          id="Fill-5"
          d="M249.2 107.2c0-18.5-13.5-32.5-30.9-32.5-17.9 0-31.4 14-31.4 32.5s13.5 32.5 31.4 32.5c17.1 0 30.9-14 30.9-32.5m-91.4 0c0-33.4 26.8-60.2 60.5-60.2 33.2 0 60 26.8 60 60.2s-26.8 60.2-60 60.2c-33.7 0-60.5-26.8-60.5-60.2"
          className="st2"
        />
        <path
          id="Fill-7"
          d="M467.1 97.6v67.2h-29v-62.1c0-17.4-8.5-28.4-21.3-28.4-13.1 0-22.7 11-22.7 28.4v62.1h-28v-62.1c0-17.4-8.8-28.4-21.7-28.4-12.9 0-22.3 11-22.3 28.4v62.1h-29v-115h29v14.7c7.1-11 18.1-17.9 31.6-17.9 14.7 0 26.3 8.3 33.4 21.2 7.3-11.5 20.8-21.2 37.8-21.2 25.5 0 42.2 22.5 42.2 51"
          className="st2"
        />
        <path
          id="Fill-9"
          d="M570.2 108.4c0-19.2-11.9-33-28.6-33-16.3 0-28.6 13.1-28.6 32.7 0 19.5 12.6 32.7 29.1 32.7 16.2-.1 28.1-13.6 28.1-32.4m-22.5 59.4c-16 0-27.8-7.3-34.6-17.4v14.4h-29V4.8h29v59c7-9.4 18.8-16.2 34.1-16.2 29.7 0 52.4 25.6 52.9 60.1.5 34.9-22.7 60.1-52.4 60.1"
          className="st2"
        />
        <path
          id="Fill-11"
          d="M612.1 164.8h29v-115h-29v115zm-3.7-147.6c0-9.8 8.2-17.2 18.1-17.2 9.6 0 17.6 7.3 17.6 17.2 0 9.8-8 17.2-17.6 17.2-9.8-.1-18.1-7.4-18.1-17.2z"
          className="st2"
        />
        <path
          id="Fill-13"
          d="M766.1 98.1v66.7h-28v-60.5c0-18.5-9.7-30-23.7-30-15.4 0-26.3 14-26.3 30.5v60h-28v-115h28v14.5c7.8-10.8 20.3-17.7 34.9-17.7 25.8 0 43.1 22 43.1 51.5"
          className="st2"
        />
        <path
          id="Fill-14"
          d="M854.1 113.8v-2.5l-29.3 5.7c-9.8 2.3-15.8 7.3-15.8 14 0 7.1 6.5 12.4 17.2 12.1 15.4-.5 27.9-13.9 27.9-29.3m28-18.4v69.4h-28v-13.3c-8.5 9.2-21.8 16-36.5 16.3-19.5 0-36.7-12.8-36.7-34.8 0-20.8 15.6-32.7 38.8-37.3l33.7-6.4c-2.3-9.6-9.6-16.9-20.7-16.9-11 0-21.3 6.9-27.5 15.6l-21.1-15.3c10.8-15.1 29.8-25.2 48.7-25.2 28.2-.2 49.3 19.3 49.3 47.9"
          className="st2"
        />
        <path
          id="Fill-15"
          d="M941.1 75.8V124c0 9.4 4.1 13.7 12.6 13.7h14.4v27h-18.4c-24.5 0-36.6-12-36.6-37.2V75.8h-21v-26h20V21.4l29-8.9v37.3h27v26h-27z"
          className="st2"
        />
        <path
          id="Fill-16"
          d="M1069 107.2c0-18.5-13.5-32.5-30.9-32.5-17.9 0-31.4 14-31.4 32.5s13.5 32.5 31.4 32.5c17.1 0 30.9-14 30.9-32.5m-91.4 0c0-33.4 26.8-60.2 60.5-60.2 33.2 0 60 26.8 60 60.2s-26.8 60.2-60 60.2c-33.7 0-60.5-26.8-60.5-60.2"
          className="st2"
        />
        <path
          id="Fill-17"
          d="M1178.1 47.3v27.9c-25.9 0-37 12.6-37 29.1v60.5h-28v-115h28v14.4c7.9-10.3 20.6-16.9 37-16.9"
          className="st2"
        />
      </g>
    </svg>
  );
};

export const BackedByYC = () => {
  return (
    <div
      aria-label="Backed by Y Combinator"
      className="bg-[#f5f5ee] hover:bg-[#ECECE6] text-black flex items-center px-4 rounded-full w-50 justify-center py-2 text-sm"
    >
      Backed by <YCSvg className="h-5 ml-2" />
    </div>
  );
};

const companyLogos = [
  {
    name: "Wefunder",
    logo: <WefunderSvg className="h-8 w-24 -translate-y-1 grayscale opacity-85" />,
    href: "https://wefunder.com",
  },
  {
    name: "Fondo",
    logo: (
      <img
        src="https://storage.googleapis.com/use-tusk-dev-static/fondo-logo.png"
        alt="Fondo Logo"
        className="h-6 w-30 translate-y-1 grayscale opacity-80"
      />
    ),
    href: "https://www.tryfondo.com/",
  },
  {
    name: "Meow",
    logo: <MeowSvg className="h-8 w-24 grayscale opacity-70" />,
    href: "https://www.meow.com/",
  },
  {
    name: "Bounce",
    logo: <BounceSvg className="h-8 w-24 grayscale opacity-80" />,
    href: "https://www.usebounce.com/",
  },
  {
    name: "Canix",
    logo: <CanixSvg className="h-8 w-24 grayscale opacity-80" />,
    href: "https://www.canix.com/",
  },
  {
    name: "HitPay",
    logo: <HitpaySvg className="h-8 w-24 grayscale opacity-80" />,
    href: "https://hitpayapp.com",
  },
  {
    name: "Replo",
    logo: <ReploSvg className="h-8 w-20 grayscale opacity-70" />,
    href: "https://www.replo.app/",
  },
  {
    name: "Prefix",
    logo: <PrefixLogoSvg className="h-8 w-24 grayscale opacity-80" />,
    href: "https://www.useprefix.com/",
  },
  {
    name: "Genie",
    logo: <GenieSvg className="h-8 w-24 grayscale opacity-80" />,
    href: "https://www.genie.io/",
  },
  {
    name: "Fundwell",
    logo: <FundwellSvg className="h-8 w-24 grayscale opacity-80" />,
    href: "https://fundwell.com/",
  },
];

export const Hero = () => {
  // const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    // Load Tally script
    const script = document.createElement("script");
    script.src = "https://tally.so/widgets/embed.js";
    script.async = true;
    document.head.appendChild(script);

    script.onload = () => {
      // @ts-ignore
      window.Tally.loadEmbeds();
    };

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  const openTallyWidget = () => {
    // @ts-ignore
    window.Tally.openPopup("nP29pQ", {
      alignLeft: 1,
      hideTitle: 1,
      transparentBackground: 1,
      dynamicHeight: 1,
    });
  };

  return (
    <div className="pb-32 pt-16 text-center lg:pt-20">
      <div className="flex justify-center w-full mb-4">
        <a
          href="https://www.ycombinator.com/companies/tusk"
          target="_blank"
          rel="noopener noreferrer"
        >
          <BackedByYC />
        </a>
      </div>
      <h1 className="mx-auto max-w-4xl font-display text-5xl font-medium tracking-tight text-slate-900 sm:text-7xl">
        AI-created{" "}
        <span className="relative whitespace-nowrap text-purple-600">
          {/* <svg
              aria-hidden="true"
              viewBox="0 0 418 42"
              className="absolute left-0 top-2/3 h-[0.58em] w-full fill-purple-600/70"
              preserveAspectRatio="none"
            >
              <path d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.78 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.81 23.239-7.825 27.934-10.149 28.304-14.005.417-4.348-3.529-6-16.878-7.066Z" />
            </svg> */}
          <span className="relative">pull requests</span>
        </span>{" "}
        for your UI tickets
      </h1>
      <p className="mx-auto mt-6 max-w-2xl text-xl tracking-tight text-slate-700">
        Raise the quality bar of your product without burdening your engineers
      </p>
      <div className="mt-12">
        <MovingBorderButton
          className="bg-slate-900 text-white hover:bg-slate-700 hover:text-slate-100 text-lg"
          onClick={openTallyWidget}
        >
          Get access
        </MovingBorderButton>
      </div>
      <div className="mt-6 md:hidden flex justify-center">
        <ProductHuntSocialProofWeek />
      </div>
      {/* <Button
        color="slate"
        onClick={() => console.log("test")}
        className="py-4 px-10 text-xl mt-12"
      >
        Get access
      </Button> */}
      <div className="mt-6 flex justify-center gap-x-6">
        {/* <Button
            href="https://www.loom.com/share/c05a1fdc9a26424b8126ef593a97e35c"
            variant="solid"
          >
            <svg
              aria-hidden="true"
              className="h-3 w-3 flex-none fill-white group-active:fill-current"
            >
              <path d="m9.997 6.91-7.583 3.447A1 1 0 0 1 1 9.447V2.553a1 1 0 0 1 1.414-.91L9.997 5.09c.782.355.782 1.465 0 1.82Z" />
            </svg>
            <span className="ml-3">Watch demo</span>
          </Button> */}
        <DemoVideo embedLink="https://www.loom.com/embed/1ce89612962b4f219fe2be9a2991d806?sid=ec94c42f-edd3-426a-a347-dc46b2f53f74?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true" />
      </div>
      <div className="mt-24 lg:mt-42">
        <p className="font-display text-base text-slate-900">
          Trusted by product leaders at high-growth companies
        </p>
        <div className="mt-8 lg:px-48 lg:gap-x-8">
          <InfiniteMovingCards
            items={companyLogos.map((company) => (
              <a
                key={company.name}
                href={company.href}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center justify-center px-6 py-4"
              >
                {company.logo}
              </a>
            ))}
            direction="left"
            speed="normal"
          />
        </div>
      </div>
    </div>
  );
};
