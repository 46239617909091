import { createRoot } from "react-dom/client";
import { PostHogProvider } from "posthog-js/react";

import { App } from "./App";
import { getEnvironment } from "./utils";

let PUBLIC_POSTHOG_KEY;
const PUBLIC_POSTHOG_HOST = "https://us.posthog.com";

switch (getEnvironment()) {
  case "prod":
    PUBLIC_POSTHOG_KEY = "phc_mUFon9ykhVY9tga0zS6TPQ7FQloQNO91PQRtXdAREqz";
    break;
  case "dev":
    PUBLIC_POSTHOG_KEY = "phc_CoiyEY9YZgTPboRDNkY9kFrrITmfivWVSgKn812ptx6";
    break;
  default:
    break;
}

const container = document.getElementById("app");
const root = createRoot(container);
root.render(
  <PostHogProvider
    apiKey={PUBLIC_POSTHOG_KEY}
    options={{
      apiHost: PUBLIC_POSTHOG_HOST,
      session_recording: {
        maskAllInputs: false,
        maskInputOptions: {
          password: true,
        },
      },
    }}
  >
    <App />
  </PostHogProvider>,
);
