import { Fragment, useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { Dialog, Transition } from "@headlessui/react";
import { clsx } from "clsx";
import {
  XMarkIcon,
  ArrowsPointingOutIcon,
  ArrowsPointingInIcon,
} from "@heroicons/react/24/outline";
import { useMutation, useQuery } from "@apollo/client";

import { gql } from "../__generatedGQL__/gql";

import { TaskAgentLog } from "../components/TaskAgentLog";
import { ErrorPage, InformationBanner, LoadingSpinner } from "../components";
import { getAppUrl, getTaskStatus } from "../utils";
import { useAppContext } from "../providers";
import {
  AddressCodeReviewRunInput,
  GeneratePullRequestRunInput,
} from "../__generatedGQL__/graphql";

const GET_TASK_WITH_ALL_RUNS = gql(`
  query GetTaskWithAllRuns($id: String!) {
    task(id: $id) {
      id
      createdAt
      updatedAt

      metadata {
        title
        description
        location
        images
        disablePullRequestCreation
      }
      externalTicketId
      externalTicketMetadata {
        identifier
        url
      }
      type
      user {
        id
        name
      }
      repo {
        id
        name
        ownerName
        defaultBranch
      }

      activeGeneratePullRequestRun {
        id
        branchName
        status

        activePullRequest {
          id
          isReadyForReview
          externalUrl
          status
          dynamicEnvUrl

          activeTestingRun {
            id
            dynamicEnvUrl
            status
            videoUrl
          }

          activeAddressCodeReviewRun {
            id
            status
          }
        }
      }

      activeInvestigationRun {
        id
        status
        logs
        createdAt
        updatedAt
      }
    
      allGeneratePullRequestRuns {
        id
        status
        logs
        createdAt
        updatedAt
        branchName

        notesMetadata {
          userNote
        }

        initialBaseCommitSha
        finalCommitSha

        allExternalCheckRuns {
          id
          status
          createdAt
          updatedAt

          allAutomatedFeedbackRuns {
            id
            status
            logs
            createdAt
            updatedAt
          }
        }

        allBrowserTestingRuns {
          id
          status
          createdAt
          updatedAt

          allAutomatedFeedbackRuns {
            id
            status
            logs
            createdAt
            updatedAt

          }
        }

        activePullRequest {
          id
          status
          createdAt
          updatedAt
          externalUrl
          dynamicEnvUrl

          allAddressCodeReviewRuns {
            id
            status
            logs
            createdAt
            updatedAt

            notesMetadata {
              userNote
            }

            finalCommitSha

            allExternalCheckRuns {
              id
              status
              createdAt
              updatedAt

              allAutomatedFeedbackRuns {
                id
                status
                logs
                createdAt
                updatedAt
              }
            }
          }
        }
      }
    }
  }
`);

const RESTORE_GENERATE_PULL_REQUEST_RUN = gql(`
  mutation RestoreGeneratePullRequestRun($taskId: String!, $restoreToGeneratePullRequestRunId: String!) {
    restoreToSpecificGeneratePullRequestRun(
      taskId: $taskId, 
      restoreToGeneratePullRequestRunId: $restoreToGeneratePullRequestRunId
    )
  }
`);

const REPLICATE_TO_TARGET_COMMIT = gql(`
  mutation ReplicateToTargetCommit($taskId: String!, $targetGeneratePullRequestRunId: String, $targetAddressCodeReviewRunId: String) {
    replicateUserRequestedRun(taskId: $taskId, targetGeneratePullRequestRunId: $targetGeneratePullRequestRunId, targetAddressCodeReviewRunId: $targetAddressCodeReviewRunId)
  }
`);

const UPDATE_GENERATE_PULL_REQUEST_RUN = gql(`
  mutation UpdateGeneratePullRequestRun($id: String!, $input: GeneratePullRequestRunInput!) {
    updateGeneratePullRequestRun(id: $id, input: $input)
  }
`);

const UPDATE_ADDRESS_CODE_REVIEW_RUN = gql(`
  mutation UpdateAddressCodeReviewRun($id: String!, $input: AddressCodeReviewRunInput!) {
    updateAddressCodeReviewRun(id: $id, input: $input)
  }
`);

interface IProps {
  showFreePlanAlert: boolean;
  taskId: string;
  open: boolean;
  closeSlideover: () => void;
}

export const TaskSlideOver = ({ showFreePlanAlert, taskId, open, closeSlideover }: IProps) => {
  const { selectedClientId } = useAppContext();
  const {
    loading: loadingTask,
    data,
    error,
    refetch,
  } = useQuery(GET_TASK_WITH_ALL_RUNS, {
    variables: {
      id: taskId,
    },
    skip: !taskId,
    pollInterval: open && 15000,
  });

  const [restoreToGeneratePullRequestRunMutation] = useMutation(RESTORE_GENERATE_PULL_REQUEST_RUN);
  const [replicateToTargetCommitMutation] = useMutation(REPLICATE_TO_TARGET_COMMIT);
  const [updateGeneratePullRequestRunMutation] = useMutation(UPDATE_GENERATE_PULL_REQUEST_RUN);
  const [updateAddressCodeReviewRunMutation] = useMutation(UPDATE_ADDRESS_CODE_REVIEW_RUN);

  useEffect(() => {
    // refetch when the slide over is opened
    if (open && taskId) {
      refetch();
    }
  }, [open, taskId, refetch]);

  const taskStatus = getTaskStatus(data?.task);

  const [largerWidth, setLargerWidth] = useState(false);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={(open) => !open && closeSlideover()}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-auto fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel
                  className={clsx("pointer-events-auto w-screen", {
                    "max-w-3xl": !largerWidth,
                    "max-w-7xl": largerWidth,
                  })}
                >
                  <div className="flex h-full flex-col bg-white shadow-xl">
                    <div className="px-4 sm:px-6 py-4">
                      <div className="flex items-start justify-between sticky top-0 z-10">
                        <div className="flex items-center justify-between">
                          <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                            Activity Log
                          </Dialog.Title>
                          <div className="ml-4">
                            {largerWidth ? (
                              <ArrowsPointingInIcon
                                onClick={() => setLargerWidth(!largerWidth)}
                                className="h-5 w-5 cursor-pointer hover:text-gray-500"
                              />
                            ) : (
                              <ArrowsPointingOutIcon
                                onClick={() => setLargerWidth(!largerWidth)}
                                className="h-5 w-5 cursor-pointer hover:text-gray-500"
                              />
                            )}
                          </div>
                        </div>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                            onClick={closeSlideover}
                          >
                            <span className="absolute -inset-2.5" />
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="flex h-full flex-col overflow-y-auto rounded-t-lg">
                      {loadingTask && <LoadingSpinner />}
                      {error && !data && (
                        <ErrorPage
                          errorCode="500"
                          errorTitle="Error loading task"
                          errorDescription={error.message}
                        />
                      )}
                      {!isEmpty(data) && (
                        <>
                          {showFreePlanAlert && (
                            <div className="px-4 sm:px-6 -mt-2 mb-2">
                              <InformationBanner>
                                The Free plan is suited for simple tickets only.{" "}
                                <a
                                  href={`${getAppUrl()}/app/settings/subscription?client=${selectedClientId}`}
                                  className="text-purple-600 hover:text-purple-800"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Upgrade
                                </a>{" "}
                                for better code quality.
                              </InformationBanner>
                            </div>
                          )}
                          <div className="relative mt-2 mb-2 ml-4 flex-1 px-4 sm:px-6">
                            <TaskAgentLog
                              task={data.task}
                              taskStatus={taskStatus}
                              onUpdateGeneratePullRequestRun={async (
                                id: string,
                                input: GeneratePullRequestRunInput,
                              ) => {
                                await updateGeneratePullRequestRunMutation({
                                  variables: { id, input },
                                });
                              }}
                              onUpdateAddressCodeReviewRun={async (
                                id: string,
                                input: AddressCodeReviewRunInput,
                              ) => {
                                await updateAddressCodeReviewRunMutation({
                                  variables: { id, input },
                                });
                              }}
                              onReplicateUserRequestedRun={async ({
                                taskId,
                                targetGeneratePullRequestRunId,
                                targetAddressCodeReviewRunId,
                              }: {
                                taskId: string;
                                targetGeneratePullRequestRunId: string;
                                targetAddressCodeReviewRunId: string;
                              }) => {
                                await replicateToTargetCommitMutation({
                                  variables: {
                                    taskId,
                                    targetGeneratePullRequestRunId,
                                    targetAddressCodeReviewRunId,
                                  },
                                });
                              }}
                              onRestoreGeneratePullRequestRun={async (
                                taskId,
                                restoreToGeneratePullRequestRunId,
                              ) => {
                                await restoreToGeneratePullRequestRunMutation({
                                  variables: {
                                    taskId,
                                    restoreToGeneratePullRequestRunId,
                                  },
                                });
                              }}
                              refetchTask={async () => {
                                await refetch();
                              }}
                              deleteRefreshTask={() => {
                                closeSlideover();
                              }}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
