import { useEffect, useState } from "react";

export const AnimatedEllipsis = () => {
  const [ellipses, setEllipses] = useState("");
  useEffect(() => {
    const animation = ["", ".", "..", "..."];

    let index = 0;

    const interval = setInterval(() => {
      setEllipses(animation[index]);
      index = (index + 1) % animation.length;
    }, 250); // Change the time to match your desired speed

    return () => clearInterval(interval);
  }, []);

  return <span>{ellipses}</span>;
};
