import { Helmet } from "react-helmet";
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";

const stats = [
  { label: "Shipping velocity", value: "2x" },
  { label: "Cost savings", value: "$7.2k" },
  { label: "ROI", value: "15x" },
];

export const PrefixCaseStudyArticle = () => {
  return (
    <>
      <Helmet>
        <title>Tusk | How Prefix Uses Tusk's AI Coding Agent to Ship 2x Faster</title>
        <meta
          name="description"
          content="Prefix doubled their speed to market and saw 15x ROI using Tusk's AI coding agent."
        />
      </Helmet>
      <div className="bg-white px-6 pt-12 pb-24 lg:px-8">
        <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
          <p className="text-base font-semibold leading-7 text-purple-600">Customer Story</p>
          <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            How Prefix Uses Tusk's AI Coding Agent to Ship 2x Faster
          </h1>
          <div className="mt-4 flex flex-wrap items-center gap-y-1 overflow-hidden text-sm leading-6 text-gray-600">
            <time className="mr-8">Oct 8, 2024</time>
            <div className="-ml-4 flex items-center gap-x-4">
              <svg viewBox="0 0 2 2" className="-ml-0.5 h-0.5 w-0.5 flex-none fill-gray-900/50">
                <circle cx={1} cy={1} r={1} />
              </svg>
              <div className="flex gap-x-2.5">
                <img
                  src="https://storage.googleapis.com/use-tusk-dev-static/marcel_tan_photo.jpg"
                  alt=""
                  className="h-6 w-6 flex-none rounded-full bg-white/10"
                />
                Marcel Tan
              </div>
            </div>
          </div>
          <figure className="mt-8">
            <img
              className="aspect-video rounded-xl bg-gray-50 object-cover"
              src="https://storage.googleapis.com/use-tusk-dev-static/Prefix%20Case%20Study%20-%20Blog%20Post%20Banner.png"
              alt="Tusk + Prefix case study banner"
            />
            <figcaption className="mt-4 flex gap-x-2 text-sm leading-6 text-gray-500">
              Prefix doubled their shipping velocity and saw 15x ROI using Tusk
            </figcaption>
          </figure>
          <h2 className="mt-8 text-2xl font-semibold tracking-tight text-gray-900">Introduction</h2>
          <p className="mt-4">
            <a
              href="https://useprefix.com"
              target="_blank"
              rel="noopener noreferrer"
              className="text-purple-600 hover:text-purple-800"
            >
              Prefix
            </a>{" "}
            helps restaurant brands conduct repair and maintenance for mission-critical facilities.
            They make it easy for facility managers to get work done faster, at better prices and
            with higher quality from vetted vendors.
          </p>
          <p className="mt-4">
            Prefix is a <strong>14-person seed stage company</strong> with a small team of
            full-stack engineers. Software engineers at Prefix are product-minded engineers who{" "}
            <strong>care deeply about craft</strong>. They are expected to own feature development
            end to end because the team does not currently have product managers.
          </p>

          <h2 className="mt-8 text-2xl font-semibold tracking-tight text-gray-900">
            Challenges Faced
          </h2>
          <p className="mt-4">
            Prefix found themselves needing to boost their engineering productivity without hiring
            more people. Given that they had a lean engineering team, the two main obstacles facing
            them were:
          </p>
          <ul role="list" className="mt-6 max-w-4xl space-y-4 text-gray-600">
            <li className="flex gap-x-3">
              <ExclamationCircleIcon
                className="mt-1 h-5 w-5 flex-none text-purple-600"
                aria-hidden="true"
              />
              <span>
                <strong className="font-semibold text-gray-900">Context switching</strong> due to
                menial frontend tickets
              </span>
            </li>
            <li className="flex gap-x-3">
              <ExclamationCircleIcon
                className="mt-1 h-5 w-5 flex-none text-purple-600"
                aria-hidden="true"
              />
              <span>
                <strong className="font-semibold text-gray-900">Cold start problem</strong> of
                taking on large, complex tickets from scratch
              </span>
            </li>
          </ul>
          <p className="mt-4">
            Dan García, founding Product Engineer at Prefix, found that context-switching for menial
            frontend tasks was causing him to <strong>lose focus time</strong>. This was time that
            could otherwise have been spent on more meaningful work. He needed a way to enable
            non-engineering team members to make copy changes and polish their UI without
            over-involving engineers.
          </p>
          <p className="mt-4">
            At the same time, Dan faced <strong>“coder’s block”</strong> when starting work on
            large, complex tickets as a product engineer who owned features end to end. The daunting
            nature of the work meant that there would be a lag time between him starting on a ticket
            and actually writing code in his IDE.
          </p>

          <h2 className="mt-8 text-2xl font-semibold tracking-tight text-gray-900">
            Tusk as a Solution
          </h2>
          <p className="mt-4">
            <a href="https://usetusk.ai">Tusk</a> is an AI coding agent that writes, pushes, and
            tests code. When Dan first heard about Tusk, he saw potential for Tusk to act as a
            virtual team member whom he could offload routine coding tasks to. In the last 6 months,
            Prefix’s Tusk implementation has met those expectations and more.
          </p>
          <figure className="mt-10 border-l border-purple-600 pl-9">
            <blockquote className="font-semibold text-gray-900">
              <p>
                "Now when working on a new feature, I start my day by firing up 5 Tusk issues while
                I work on something else or make my coffee. When I return, I have 5 Tusk PRs ready
                to go and it gets me super excited. Starting on a big, complex ticket can be
                difficult because of coder’s block. But if I have code generated by Tusk already, it
                gives me the momentum to get it done quicker."
              </p>
            </blockquote>
            <figcaption className="mt-6 flex gap-x-3">
              <img
                className="h-6 w-6 flex-none rounded-full bg-gray-50"
                src="https://storage.googleapis.com/use-tusk-dev-static/daniel-garcia-photo.jpeg"
                alt=""
              />
              <div className="text-sm leading-6">
                <strong className="font-semibold text-gray-900">Dan García</strong> – Founding
                Product Engineer at Prefix
              </div>
            </figcaption>
          </figure>
          <p className="mt-10">
            Dan was drawn to the asynchronous nature of Tusk. Unlike other AI coding tools, Tusk
            could complete simple frontend tasks like copy changes and styling tweaks with no human
            intervention. This meant that Prefix’s engineers no longer had to be bothered by these
            mundane tasks.
          </p>
          <p className="mt-4">
            Dan especially enjoyed using Tusk to generate multiple branches for larger tickets
            (i.e., tickets spanning both frontend and backend) at one go. Tusk’s code scaffolding
            for these tickets would often get <strong>80% of the way</strong> to a mergeable state.
            Dan would then checkout each of the branches that Tusk created, push the remaining 20%
            of work, and merge the PRs.
          </p>
          <figure className="mt-8">
            <img
              className="aspect-video rounded-xl bg-gray-50 object-cover"
              src="https://storage.googleapis.com/use-tusk-dev-static/Prefix%20Case%20Study%20-%20Workflow%20Graphic.png"
              alt="Prefix's workflow for Tusk to ship a net new feature"
            />
            <figcaption className="mt-4 flex gap-x-2 text-sm leading-6 text-gray-500">
              Prefix's workflow for using Tusk to ship net new features
            </figcaption>
          </figure>
          <p className="mt-8">
            He now leverages Tusk alongside a AI-powered code editor as a powerful, complementary
            pairing to ship twice as fast. He uses Tusk to generate the bulk of the code for large
            tickets, then jumps into his AI-powered code editor to refine the remaining bit to a
            mergeable state.
          </p>

          <h2 className="mt-8 text-2xl font-semibold tracking-tight text-gray-900">
            Improves Over Time
          </h2>
          <figure className="mt-10 border-l border-purple-600 pl-9">
            <blockquote className="font-semibold text-gray-900">
              <p>
                “Tusk gets better over time in understanding our unique codebase context, so the
                code quality improves as we’re using it. It’s not like ChatGPT where I would need to
                spoon-feed it a complex prompt and copy-and-paste multiple files before asking it to
                generate code.”
              </p>
            </blockquote>
            <figcaption className="mt-6 flex gap-x-3">
              <img
                className="h-6 w-6 flex-none rounded-full bg-gray-50"
                src="https://storage.googleapis.com/use-tusk-dev-static/daniel-garcia-photo.jpeg"
                alt=""
              />
              <div className="text-sm leading-6">
                <strong className="font-semibold text-gray-900">Dan García</strong> – Founding
                Product Engineer at Prefix
              </div>
            </figcaption>
          </figure>
          <p className="mt-10">
            Prefix found that Tusk’s long term memory was one of its core strengths. Tusk would get
            better at writing code for their codebase the more that they used it. The AI agent would
            learn about Prefix’s code patterns and best practices from past PRs and code reviews,
            then accurately reference these insights when completing new tasks.
          </p>
          <p className="mt-4">
            One example that Dan highlighted was how Tusk was smart enough to find an existing
            search bar component that he had entirely forgotten about when he had assigned Tusk to
            create a search bar for a table. Tusk reused the existing component to make the most
            elegant change—all without an engineer guiding it.
          </p>

          <h2 className="mt-8 text-2xl font-semibold tracking-tight text-gray-900">Result</h2>
          <p className="mt-4">
            One major project that Dan took on recently was replacing a third-party chat component
            in Prefix’s app with a robust, in-house comment section. Initially scoped for{" "}
            <strong>3 weeks</strong>, they <strong>completed it in 1.5 weeks</strong> with the help
            of Tusk.
          </p>
          <p className="mt-4">
            Tusk helped break down the task into smaller components, handle migrations, and even
            surprised Dan by correctly implementing CRUD operations. While Tusk still helps Prefix
            out with automating away menial frontend work, it has become an integral part to
            shipping net new features faster.
          </p>
          <h3 className="mt-8 text-xl font-semibold tracking-tight text-gray-900">
            In the last 2 months
          </h3>
          <figure className="mt-10 border-l border-purple-600 pl-9">
            <blockquote className="font-semibold text-gray-900">
              <p>
                “I write less code but I ship more. This is my new flow and I don’t want to go back
                to how I was working before.”
              </p>
            </blockquote>
            <figcaption className="mt-6 flex gap-x-3">
              <img
                className="h-6 w-6 flex-none rounded-full bg-gray-50"
                src="https://storage.googleapis.com/use-tusk-dev-static/daniel-garcia-photo.jpeg"
                alt=""
              />
              <div className="text-sm leading-6">
                <strong className="font-semibold text-gray-900">Dan García</strong> – Founding
                Product Engineer at Prefix
              </div>
            </figcaption>
          </figure>
          <dl className="mt-10 grid grid-cols-1 gap-8 sm:grid-cols-3">
            {stats.map((stat) => (
              <div key={stat.label}>
                <dt className="text-sm font-semibold leading-6 text-gray-600">{stat.label}</dt>
                <dd className="mt-2 text-3xl font-bold leading-10 tracking-tight text-gray-900">
                  {stat.value}
                </dd>
              </div>
            ))}
          </dl>
          <p className="mt-8">
            By embedding Tusk into their development workflow as an extra pair of coding hands,
            Prefix overcame engineering bandwidth constraints. Tusk's ability to generate
            high-quality code for larger tickets reduced cold start times and doubled their speed to
            market.
          </p>
          <div className="mt-10 border-t border-gray-900/10 pt-10">
            <p>
              If you'd like to raise the quality bar of your product while shipping faster,{" "}
              <a
                href="https://cal.com/marceltan/demo"
                className="text-purple-600 hover:text-purple-800"
                target="_blank"
                rel="noopener noreferrer"
              >
                book a demo with the Tusk founders.
              </a>
            </p>
            <p className="mt-4">
              If you're a restaurant brand curious about offloading your facility maintenance to a
              trusted partner, reach out to{" "}
              <a
                href="mailto:jared@useprefix.com"
                className="text-purple-600 hover:text-purple-800"
              >
                jared@useprefix.com
              </a>{" "}
              to learn more.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
