import { useMemo } from "react";

import { ConnectExternalResource } from "../../components";
import { useAppContext } from "../../providers";
import { GetResourcesQuery } from "../../__generatedGQL__/graphql";

export const LinearAuth = ({ resource }: { resource?: GetResourcesQuery["resources"][0] }) => {
  const { user, selectedClientId } = useAppContext();

  const linearEnabled = !!resource;

  const linearAuthUrl = useMemo(() => {
    const state = {
      userId: user.id,
      clientId: selectedClientId,
    };

    return `https://linear.app/oauth/authorize?response_type=code&client_id=${
      process.env.LINEAR_CLIENT_ID
    }&redirect_uri=${
      process.env.LINEAR_REDIRECT_URI
    }&scope=read,write&actor=application&state=${JSON.stringify(state)}`;
  }, [user.id, selectedClientId]);

  return (
    <ConnectExternalResource
      authed={linearEnabled}
      onConnect={() => window.location.assign(linearAuthUrl)}
      resourceName="Linear"
      resourceDescription="Assign issues using a Linear label"
      resourceLogo="https://asset.brandfetch.io/iduDa181eM/id-IUp6ZRH.png"
    />
  );
};
