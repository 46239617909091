type Environment = "local" | "dev" | "prod";

export const getEnvironment = (): Environment => {
  const hostname = window?.location?.hostname;

  if (hostname.includes("localhost") || hostname.includes("127.0.0.1")) {
    return "local";
  } else if (hostname === "use-tusk-dev.web.app" || hostname.includes("use-tusk-dev")) {
    return "dev";
  } else if (hostname === "usetusk.ai" || hostname === "use-tusk.web.app") {
    return "prod";
  } else {
    return "dev";
  }
};

export const getAppUrl = (): string => {
  switch (getEnvironment()) {
    case "local":
      return "http://localhost:1234";
    case "dev":
      return "https://use-tusk-dev.web.app";
    case "prod":
      return "https://usetusk.ai";
  }
};
