import { Button } from "./Button";

interface IProps {
  totalTasks: number;
  indexOfFirstTask: number;
  indexOfLastTask: number;
  goToPreviousPage: () => void;
  goToNextPage: () => void;
  previousButtonDisabled: boolean;
  nextButtonDisabled: boolean;
}

export const IssuesTablePagination = ({
  totalTasks,
  indexOfFirstTask,
  indexOfLastTask,
  goToPreviousPage,
  goToNextPage,
  previousButtonDisabled,
  nextButtonDisabled,
}: IProps) => {
  return (
    <nav
      className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
      aria-label="Pagination"
    >
      <div className="hidden sm:block">
        <p className="text-sm text-gray-700">
          Showing <span className="font-medium">{totalTasks === 0 ? 0 : indexOfFirstTask}</span> to{" "}
          <span className="font-medium">{totalTasks === 0 ? 0 : indexOfLastTask}</span> of{" "}
          <span className="font-medium">{totalTasks}</span> issues
        </p>
      </div>
      <div className="flex flex-1 justify-between sm:justify-end">
        <Button variant="secondary" onClick={goToPreviousPage} disabled={previousButtonDisabled}>
          Previous
        </Button>
        <Button
          variant="secondary"
          onClick={goToNextPage}
          disabled={nextButtonDisabled}
          className="ml-3"
        >
          Next
        </Button>
      </div>
    </nav>
  );
};
