import { map, range } from "lodash";

import { InfiniteMovingCards } from "./InfiniteMovingCards";
import { Container } from "../components/Container";
import { cn } from "../utils";
import {
  DispatchSvg,
  HitpaySvg,
  JarvysSvg,
  KapstanSvg,
  TuskSvg,
  WefunderSvg,
  UserologySvg,
  ReploSvg,
  BounceSvg,
  CanixSvg,
} from "./CompanyLogos";

const GitMergeSvg = ({ className }: { className?: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" className={cn("h-4 w-4", className)}>
    <path d="M5.45 5.154A4.25 4.25 0 0 0 9.25 7.5h1.378a2.251 2.251 0 1 1 0 1.5H9.25A5.734 5.734 0 0 1 5 7.123v3.505a2.25 2.25 0 1 1-1.5 0V5.372a2.25 2.25 0 1 1 1.95-.218ZM4.25 13.5a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Zm8.5-4.5a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM5 3.25a.75.75 0 1 0 0 .005V3.25Z"></path>
  </svg>
);

interface IMergedIssueProps {
  title: string;
  description: string;
  linesAdded: number;
  linesRemoved: number;
  CompanyLogo?: React.ElementType;
  [key: string]: any;
}

const MergedIssue = ({
  title,
  description,
  linesAdded,
  linesRemoved,
  CompanyLogo,
}: IMergedIssueProps) => {
  return (
    <figure className="relative rounded-2xl bg-white p-5 w-[440px] flex flex-col justify-between shadow-md shadow-slate-900/10">
      <p className="w-full flex-none text-lg font-semibold tracking-tight line-clamp-1 text-gray-900">
        {title}
      </p>
      <p className="mt-2 w-full flex-none text-base leading-7 text-gray-600 h-[80px] line-clamp-3">
        {description}
      </p>
      <div className="mt-4 w-full flex justify-between items-center">
        <div className="flex items-center">
          <div
            className="bg-[#8205DF] text-white flex items-center px-4 py-1 rounded-full font-bold"
            style={{ width: "fit-content" }}
          >
            <GitMergeSvg className="fill-white mr-2" />
            Merged
          </div>
          <p className="text-[#1A7F37] font-semibold ml-4">+{linesAdded}</p>
          <p className="text-[#D1242F] font-semibold ml-1">-{linesRemoved}</p>
        </div>
        {CompanyLogo && <CompanyLogo className="ml-4 h-6 w-20" />}
      </div>
    </figure>
  );
};

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]]; // Swap elements
  }
}

// Function to split an array into two random parts
function splitAndShuffleArray(originalArray) {
  shuffleArray(originalArray); // Shuffle the original array

  // Calculate the split index
  const splitIndex = Math.ceil(originalArray.length / 2);

  // Split the array into two parts
  const firstHalf = originalArray.slice(0, splitIndex);
  const secondHalf = originalArray.slice(splitIndex);

  return [firstHalf, secondHalf];
}

export const InfiniteTuskIssues = () => {
  // randomize mergedIssues into two arrays
  const [array1, array2] = splitAndShuffleArray(mergedIssues);

  return (
    <>
      <InfiniteMovingCards
        items={map(array1, (issue) => (
          <MergedIssue {...issue} />
        ))}
        direction="right"
        speed="slow"
      />
      <InfiniteMovingCards
        items={map(array2, (issue) => (
          <MergedIssue {...issue} />
        ))}
        direction="left"
        speed="slow"
      />
    </>
  );
};

export const MergedTuskIssues = () => {
  return (
    <section className="relative overflow-hidden bg-purple-50 py-20 sm:py-32">
      <div>
        <div className="mx-auto px-6 lg:px-8">
          <div className="mx-auto max-w-xl text-center">
            <p className="mt-2 text-3xl font-display tracking-tight text-gray-900 sm:text-4xl">
              Tusk actually works. Check out some of our merged PRs.
            </p>
            <p className="mt-6 text-lg tracking-tight leading-8 text-slate-600">
              These are <b>real issues</b> for <b>real users</b> that our AI agent took care of.
            </p>
            <p className="text-lg tracking-tight leading-8 text-slate-600">
              No human assistance needed besides reviewing and merging the PR.
            </p>
          </div>
          <div className="mx-auto mt-16 flow-root w-full sm:mt-20 lg:mx-0 lg:max-w-none">
            <InfiniteTuskIssues />
          </div>
        </div>
      </div>
    </section>
  );
};

const mergedIssues: IMergedIssueProps[] = [
  {
    title: "Fix bug in generate flow starter options",
    description:
      "When I click next between the generate flow starter options, once I get to the end, I have to click the next button twice before it repeats to the start of the loop of options.",
    status: "merged",
    linesAdded: 12,
    linesRemoved: 18,
    CompanyLogo: JarvysSvg,
    clientName: "jarvys",
    clientId: "e81c5dae-f77b-40f5-80a9-72875f805cda",
  },
  {
    title: "Cancel and uninstall button in the delete app confirmation modal shouldn't be all caps",
    description:
      "As shown in image, cancel and uninstall buttons shouldn’t be all caps.\n\nChange “CANCEL” to “Cancel” and “UNINSTALL” to “Uninstall”\n\nIf you find any other buttons like this with all capital letters, change them to sentence case.",
    status: "merged",
    linesAdded: 16,
    linesRemoved: 16,
    CompanyLogo: KapstanSvg,
    clientName: "kapstan",
    clientId: "ed50094e-1691-43de-bffb-0118116e7ec6",
  },
  {
    title: "Clicking on application cards should always take to deploy tab",
    description:
      'Whenever I click on a container app on "applications" page; it takes me to "container settings" screen.\n\nHowever when I click on chart app card, it takes me to "deployment" screen.\n\nFor all types of applications, it should navigate to deployment tab instead of container settings.',
    status: "merged",
    linesAdded: 2,
    linesRemoved: 2,
    CompanyLogo: KapstanSvg,
    clientName: "kapstan",
    clientId: "ed50094e-1691-43de-bffb-0118116e7ec6",
  },
  {
    title: "Server error on list invoice",
    description:
      "API endpoint: GET v1/business/{{business_id}}/invoice\nThe error: Call to undefined relationship [paymentRequest] on model [App\\Business\\Invoice]",
    status: "merged",
    linesAdded: 3,
    linesRemoved: 4,
    CompanyLogo: HitpaySvg,
    clientName: "hit-pay",
    clientId: "af6b0df2-f418-4046-906e-618721aa0dab",
  },
  {
    title: "Update the minimum cart amount for create/update discount",
    description:
      'AR: When entering minimum_cart_amount > 999999.99 it returns error minimum_cart_amount : "The minimum cart amount must be between 0 and 999999.99."\n\nER: \n\n* Remove the upper limit for minimum cart amount so that Indonesian users can set minimum cart amount',
    status: "merged",
    linesAdded: 2,
    linesRemoved: 2,
    CompanyLogo: HitpaySvg,
    clientName: "hit-pay",
    clientId: "af6b0df2-f418-4046-906e-618721aa0dab",
  },
  {
    title: "Error: Call to a member function notify() on null",
    description:
      "Sentry Issue: https://hitpay.sentry.io/issues/4797805798/?referrer=Linear. Error: Call to a member function notify() on null",
    status: "merged",
    linesAdded: 4,
    linesRemoved: 2,
    CompanyLogo: HitpaySvg,
    clientName: "hit-pay",
    clientId: "af6b0df2-f418-4046-906e-618721aa0dab",
  },
  {
    title: "Partial refund charges are still returned when filtering with succeeded charge filter",
    description: "Filtering with succeeded charge filter should not return partial refund charges",
    status: "merged",
    linesAdded: 2,
    linesRemoved: 1,
    CompanyLogo: HitpaySvg,
    clientName: "hit-pay",
    clientId: "af6b0df2-f418-4046-906e-618721aa0dab",
  },
  {
    title: "Default set country to business' country",
    description:
      "When adding location, in the country dropdown select, please set country to the business' country by default ",
    status: "merged",
    linesAdded: 1,
    linesRemoved: 0,
    CompanyLogo: HitpaySvg,
    clientName: "hit-pay",
    clientId: "af6b0df2-f418-4046-906e-618721aa0dab",
  },
  {
    title: "CS - Payments bug",
    description:
      "Transaction Report: Report export does not include Cashier Name even if it's selected on the required fields.",
    status: "merged",
    linesAdded: 2,
    linesRemoved: 0,
    CompanyLogo: HitpaySvg,
    clientName: "hit-pay",
    clientId: "af6b0df2-f418-4046-906e-618721aa0dab",
  },
  {
    title:
      "Missing the successful message and reload the page after generating and removing the API key",
    description:
      "Bug recording: https://app.birdeatsbug.com/cx15TJFSOkdnmtjHi8n1UEwWSWqK0-BcPV-hq-g-jcCa",
    status: "merged",
    linesAdded: 4,
    linesRemoved: 8,
    CompanyLogo: HitpaySvg,
    clientName: "hit-pay",
    clientId: "af6b0df2-f418-4046-906e-618721aa0dab",
  },
  {
    title: "Create button is disabled for empty payment links",
    description:
      "Only for payment links user should be able to save without entering any details:\nAR (Create button is disabled)\n\n![Screenshot 2024-04-22 at 5.25.54 PM.png](Image URL index 0)\n\nER: Create button should be enabled even if all fields are empty",
    status: "merged",
    linesAdded: 2,
    linesRemoved: 2,
    CompanyLogo: HitpaySvg,
    clientName: "hit-pay",
    clientId: "af6b0df2-f418-4046-906e-618721aa0dab",
  },
  {
    title: "Dynamic name change from user to actual user name ",
    description:
      "There is a name added to it that has come from the name entered by the user during the start of the session in the name field. Instead of constant 'user'; make the change",
    status: "merged",
    linesAdded: 26,
    linesRemoved: 9,
    CompanyLogo: UserologySvg,
    clientName: "userology",
    clientId: "6bf7c5cf-e438-4ae2-81ea-781c991f2641",
  },
  {
    title: "Adding timer that works",
    description:
      "The timer on bottom left of the page is not functional. It is expected to start as a stopwatch when the user clicks on continue button after allowing permissions.",
    status: "merged",
    linesAdded: 74,
    linesRemoved: 10,
    clientName: "userology",
    clientId: "6bf7c5cf-e438-4ae2-81ea-781c991f2641",
    CompanyLogo: UserologySvg,
  },
  {
    title: "Add links to open jobs",
    description:
      'Add a "Join our team" section underneath the team section. The section should have a title that says "Interested in joining our team?" and a description that is cohesive with the rest of the page\'s copy. It should contain one link out for the "Head of Sales" job that links to this posting in a new tab: https://dispatch.breezy.hr/p/824986648376-head-of-sales\n\nThis section should be styled cohesively with the rest of the page.',
    status: "merged",
    linesAdded: 18,
    linesRemoved: 0,
    CompanyLogo: DispatchSvg,
    clientName: "dispatch",
    clientId: "05117556-8929-4dff-84fd-fdd73d1d3229",
  },
  {
    title: "Add photo & bio criteria to /edit page",
    description:
      'New copy: "Get investors excited about your team. Write about specific accomplishments that build credibility and trust.\n\nTo be live on Wefunder, all team members must have a photo and bio."',
    status: "merged",
    linesAdded: 1,
    linesRemoved: 1,
    CompanyLogo: WefunderSvg,
    clientName: "Wefunder",
    clientId: "b76deaa1-376e-412b-8c42-248f28ddfcef",
  },
  {
    title: 'Change "followers" to "follower" when followers=1',
    description:
      'On user profile pages, wefunder.com/<user_slug>, the bottom line says the number of followers. If there is only 1 follower, say "1 Follower" instead of "1 Followers" like it currently does.',
    linesAdded: 4,
    linesRemoved: 1,
    CompanyLogo: WefunderSvg,
    clientName: "Wefunder",
    clientId: "b76deaa1-376e-412b-8c42-248f28ddfcef",
  },
  {
    title: "Open the Activity Log when you click on a row in the Issues table",
    description:
      "When you click on a row in the Issues table, it should open the Activity Log side panel for the specific issue. Make sure that you're still able to click on the IssueOptions button to view the menu items. When you hover on a row in the Issues table, it should appear as a slightly darker color.",
    linesAdded: 18,
    linesRemoved: 12,
    clientName: "tusk",
    CompanyLogo: TuskSvg,
  },
  {
    title: 'When there are no issues, pagination says "Showing 0 to 1 of 0 issues"',
    description:
      'This is a bug. If there are no issues in the table, it should be saying "Showing 0 to 0 of 0 issues".',
    linesAdded: 5,
    linesRemoved: 6,
    clientName: "tusk",
    CompanyLogo: TuskSvg,
  },
  {
    title: "Make repository optional in Create Issue modal",
    description:
      '"Repository" field should be optional for the user. They don\'t have to select anything in the field to create an issue. Include a tooltip next to the field title saying "Do not select a repository if you want Tusk to auto-select the right one."',
    linesAdded: 7,
    linesRemoved: 2,
    clientName: "tusk",
    CompanyLogo: TuskSvg,
  },
  {
    title: "[Bug] Clicking repo label checks unrelated repos",
    description:
      "Clicking on the checkbox label selects unrelated repos. Watch the screen recording here: https://jam.dev/c/5e1331ba-41a0-4d26-89f7-769865961df4",
    linesAdded: 1,
    linesRemoved: 1,
    clientName: "tusk",
    CompanyLogo: TuskSvg,
  },
  {
    title: 'Renaming "Page" should not be possible in tree',
    description:
      "this shouldn't be editable. In ComponentTreePane, we need to check whether there's no parent (whether it's the root) before allowing the double-click action to replace the tree item title with an input.",
    linesAdded: 5,
    linesRemoved: 1,
    clientName: "replo",
    CompanyLogo: ReploSvg,
    clientId: "f356172f-a54f-46d3-8871-25f3020dff56",
  },
  {
    title:
      "Pylon chat widget in EditorApp should be dynamically updated to be just to the right of the ResizablePane",
    description:
      "Needs to be dynamically updated to be just to the right of the ResizablePane: https://www.loom.com/share/39df70435uc8w6084vf 6",
    linesAdded: 5,
    linesRemoved: 2,
    clientName: "replo",
    CompanyLogo: ReploSvg,
    clientId: "f356172f-a54f-46d3-8871-25f3020dff56",
  },
  {
    title: "Disable License Number Field from being edited",
    description:
      "Problem: Users are currently able to update the license number of their facilities. This can cause problems with syncing since we sync based off the API key and license number. AC: Disable the License Number field on the Edit Facility modal in Facility Management",
    linesAdded: 9,
    linesRemoved: 12,
    clientName: "canix",
    CompanyLogo: CanixSvg,
    clientId: "4fa49a66-a061-410f-88b5-7588e08d0c13",
  },
  {
    title: "Remove table toast error for saved view changes",
    description:
      "Problem: We provide this toast error on all tables. We want to remove it as it doesn't provide the customer information on what changed, and the changes have not been affecting our customers to warrant a message. There is a bug that shows this message every time you open a page with a table and has been annoying customers.",
    linesAdded: 6,
    linesRemoved: 40,
    clientName: "canix",
    CompanyLogo: CanixSvg,
    clientId: "4fa49a66-a061-410f-88b5-7588e08d0c13",
  },
  {
    title: "Add analytics to customer app interactions",
    description:
      "1. We need to add an Analytics event on the “Edit” option on the booking details page for customer bookings interactions so we can measure the usage.",
    linesAdded: 20,
    linesRemoved: 3,
    clientName: "bounce",
    CompanyLogo: BounceSvg,
    clientId: "f2b9178a-a7ab-4959-98eb-2e02bd376954",
  },
];
