import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import { CopyText } from "../components";

export const RepoLimitAlert = ({ maxRepos, plan }: { maxRepos: number; plan: string }) => {
  return (
    <div className="rounded-md bg-yellow-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-yellow-800">Upgrade to sync more repos</h3>
          <div className="mt-2 text-sm text-yellow-700">
            <p>
              You can only sync {maxRepos} GitHub repo{maxRepos === 1 ? "" : "s"} on the {plan}{" "}
              plan. Contact <CopyText text="founders@usetusk.ai" type="email" /> to upgrade your
              plan.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
