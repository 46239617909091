import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { gql } from "../__generatedGQL__/gql";
import { useMutation, useQuery } from "@apollo/client";
import { PageHeading, LoadingSpinner, ErrorPage, IssuesTablePagination } from "../components";
import { useAppContext, useNotificationContext } from "../providers";
import { isEmpty, map, sortBy } from "lodash";
import {
  AutoTriageCandidateCard,
  doesCandidateHaveEnoughInfo,
} from "../components/AutoTriageCandidateCard";
import { getAppUrl } from "../utils";
import { AutoTriageEmptyState } from "../components/AutoTriageEmptyState";
import { useExternalTicketingHook } from "../hooks/useExternalTicketingHook";

const GET_AUTO_TRIAGE_CANDIDATES = gql(`
query GetAutoTriageCandidates {
  autoTriageCandidates {
    runId
    runCreatedAt
    source

    title
    description
    images

    externalTicketIdentifier
    externalTicketUrl
    externalTicketCreatedAt

    hasDetailedDescription
    clarifyingQuestions

    resource {
      type
    }
  }
}`);

const DISMISS_AUTO_TRIAGE_CANDIDATE = gql(`
  mutation DismissAutoTriageCandidate($autoTriageRunId: String!) {
    dismissAutoTriageCandidate(autoTriageRunId: $autoTriageRunId)
  }
`);

const CREATE_TASK_FROM_AUTO_TRIAGE_CANDIDATE = gql(`
  mutation CreateTaskFromAutoTriageCandidate($autoTriageRunId: String!) {
    createTaskFromAutoTriageCandidate(autoTriageRunId: $autoTriageRunId) {
      id
    }
  }
`);

export const AutoTriagePage = () => {
  const { user, selectedClientId } = useAppContext();
  const { showNotification } = useNotificationContext();

  const { loading, error, data, refetch } = useQuery(GET_AUTO_TRIAGE_CANDIDATES, {
    fetchPolicy: "cache-and-network",
  });
  const [dismissAutoTriageCandidate] = useMutation(DISMISS_AUTO_TRIAGE_CANDIDATE);
  const [createTaskFromAutoTriageCandidate] = useMutation(CREATE_TASK_FROM_AUTO_TRIAGE_CANDIDATE);

  const { isExternalTicketingConnected, hasEnabledRepos } = useExternalTicketingHook();

  const [currentFilter, setCurrentFilter] = useState<"all" | "sufficient" | "needs_more">("all");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 9;

  const { paginatedCandidates, totalFilteredCandidates } = useMemo(() => {
    if (!data) return { paginatedCandidates: [], totalFilteredCandidates: 0 };
    let filteredCandidates = data.autoTriageCandidates;
    if (currentFilter === "sufficient") {
      filteredCandidates = filteredCandidates.filter(doesCandidateHaveEnoughInfo);
    } else if (currentFilter === "needs_more") {
      filteredCandidates = filteredCandidates.filter(
        (candidate) => !doesCandidateHaveEnoughInfo(candidate),
      );
    }
    const sortedCandidates = sortBy(
      filteredCandidates,
      (candidate) => !doesCandidateHaveEnoughInfo(candidate),
    );

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return {
      paginatedCandidates: sortedCandidates.slice(startIndex, endIndex),
      totalFilteredCandidates: sortedCandidates.length,
    };
  }, [data, currentFilter, currentPage]);

  const totalPages = Math.ceil(totalFilteredCandidates / itemsPerPage);

  const goToNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const goToPreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [currentFilter]);

  useEffect(() => {
    setCurrentPage(1);
    setCurrentFilter("all");
  }, [selectedClientId]);

  if (loading && !data) return <LoadingSpinner />;
  if (error)
    return (
      <ErrorPage
        errorCode="500"
        errorTitle="Error loading auto triage page"
        errorDescription={error.message}
      />
    );

  // TODO: Pass in isExternalTicketingConnected after fetching from API

  return (
    <div>
      <PageHeading
        name="Suggested Issues"
        subheader={
          <p className="mt-4 text-sm text-gray-700">
            Tusk auto-surfaces issues from your project management app that are suitable for it to
            create a pull request for.
          </p>
        }
      />
      <div className="mt-4 mb-6 flex space-x-2">
        <button
          onClick={() => {
            setCurrentFilter("all");
            setCurrentPage(1);
          }}
          className={`px-3 py-1 rounded-full text-sm font-medium ${
            currentFilter === "all"
              ? "bg-purple-600 text-white"
              : "bg-gray-100 text-gray-800 hover:bg-gray-200"
          }`}
        >
          All
        </button>
        <button
          onClick={() => {
            setCurrentFilter("sufficient");
            setCurrentPage(1);
          }}
          className={`px-3 py-1 rounded-full text-sm font-medium ${
            currentFilter === "sufficient"
              ? "bg-purple-600 text-white"
              : "bg-gray-100 text-gray-800 hover:bg-gray-200"
          }`}
        >
          Sufficient info
        </button>
        <button
          onClick={() => {
            setCurrentFilter("needs_more");
            setCurrentPage(1);
          }}
          className={`px-3 py-1 rounded-full text-sm font-medium ${
            currentFilter === "needs_more"
              ? "bg-purple-600 text-white"
              : "bg-gray-100 text-gray-800 hover:bg-gray-200"
          }`}
        >
          Needs more info
        </button>
      </div>
      {isEmpty(paginatedCandidates) && (
        <div className="mt-6">
          {isEmpty(data.autoTriageCandidates) ? (
            <AutoTriageEmptyState isExternalTicketingConnected={isExternalTicketingConnected} />
          ) : currentPage === 1 ? (
            <p className="text-center text-gray-500">No issues match the selected filter.</p>
          ) : (
            <p className="text-center text-gray-500">No more issues on this page.</p>
          )}
        </div>
      )}
      <ul role="list" className="grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-3">
        {map(paginatedCandidates, (candidate) => (
          <AutoTriageCandidateCard
            key={candidate.runId}
            candidate={candidate}
            hasEnabledRepos={hasEnabledRepos}
            onAssignToTusk={async () => {
              try {
                const { data } = await createTaskFromAutoTriageCandidate({
                  variables: { autoTriageRunId: candidate.runId },
                });
                await refetch();
                showNotification({
                  type: "success",
                  title: `Assigned ${candidate.externalTicketIdentifier || "issue"} to Tusk 🎉`,
                  message: `Tusk will create a pull request for the issue in about 15 minutes. Check the [activity log](${getAppUrl()}/app/task/${
                    data.createTaskFromAutoTriageCandidate.id
                  }?client=${selectedClientId}) for details.`,
                });
              } catch (error) {
                console.error(error);
                showNotification({
                  type: "error",
                  title: `Error assigning ${candidate.externalTicketIdentifier || "issue"} to Tusk`,
                  message: error.message,
                });
              }
            }}
            onDismiss={async () => {
              try {
                await dismissAutoTriageCandidate({
                  variables: { autoTriageRunId: candidate.runId },
                });
                await refetch();
                showNotification({
                  type: "success",
                  title: `Dismissed ${candidate.externalTicketIdentifier || "issue"}`,
                });
              } catch (error) {
                console.error(error);
                showNotification({
                  type: "error",
                  title: `Error dismissing ${candidate.externalTicketIdentifier || "ticket"}`,
                  message: error.message,
                });
              }
            }}
          />
        ))}
      </ul>
      <div className="mt-8">
        <IssuesTablePagination
          totalTasks={totalFilteredCandidates}
          indexOfFirstTask={(currentPage - 1) * itemsPerPage + 1}
          indexOfLastTask={Math.min(currentPage * itemsPerPage, totalFilteredCandidates)}
          goToPreviousPage={goToPreviousPage}
          goToNextPage={goToNextPage}
          previousButtonDisabled={currentPage === 1 || totalFilteredCandidates === 0}
          nextButtonDisabled={currentPage === totalPages || totalFilteredCandidates === 0}
        />
      </div>
    </div>
  );
};
