import { clsx } from "clsx";

const outerSize = {
  sm: "h-7 w-7",
  lg: "h-14 w-14",
};

const innerSize = {
  sm: "h-4 w-4",
  lg: "h-9 w-9",
};

export const LoadingSpinner = ({ size = "lg" }: { size?: "sm" | "lg" }) => {
  return (
    <div className="flex justify-center items-center">
      <div
        className={clsx(
          "rounded-full bg-gradient-to-tr from-indigo-500 to-pink-500 animate-spin flex items-center justify-center",
          outerSize[size],
        )}
      >
        <div className={clsx("rounded-full bg-white", innerSize[size])}></div>
      </div>
    </div>
  );
};
