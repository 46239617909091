export const ProductHuntSocialProofWeek = () => {
  return (
    <a
      href="https://www.producthunt.com/posts/tusk-yc-w24?embed=true&utm_source=badge-top-post-topic-badge&utm_medium=badge&utm_souce=badge-tusk&#0045;yc&#0045;w24"
      target="_blank"
    >
      <img
        src="https://api.producthunt.com/widgets/embed-image/v1/top-post-topic-badge.svg?post_id=475622&theme=light&period=weekly&topic_id=237"
        alt="Tusk&#0032;&#0040;YC&#0032;W24&#0041; - Make&#0032;UI&#0032;improvements&#0032;with&#0032;AI | Product Hunt"
        width="250"
        height="54"
      />
    </a>
  );
};
