import { useMemo } from "react";

import { ConnectExternalResource } from "../../components";
import { useAppContext } from "../../providers";
import { GetResourcesQuery } from "../../__generatedGQL__/graphql";

export const FigmaAuth = ({ resource }: { resource: GetResourcesQuery["resources"][0] }) => {
  const { user, selectedClientId } = useAppContext();
  const figmaEnabled = !!resource;

  const figmaAuthUrl = useMemo(() => {
    const state = {
      clientId: selectedClientId,
      userId: user.id,
    };
    return `https://www.figma.com/oauth?client_id=${
      process.env.FIGMA_CLIENT_ID
    }&scope=files:read,file_dev_resources:read&response_type=code&redirect_uri=${encodeURIComponent(
      process.env.FIGMA_REDIRECT_URI,
    )}&state=${encodeURIComponent(JSON.stringify(state))}`;
  }, [selectedClientId, user.id]);

  return (
    <ConnectExternalResource
      authed={figmaEnabled}
      onConnect={() => window.location.assign(figmaAuthUrl)}
      resourceName="Figma"
      resourceLogo="https://asset.brandfetch.io/idZHcZ_i7F/ida1pqjhS_.png"
      resourceDescription="Extract information from Figma links"
    />
  );
};
