import React from "react";

export const AutomatedTestsBanner = () => {
  return (
    <div className="bg-purple-600 text-white text-center py-2">
      <p className="text-sm">
        Tusk now generates unit and integration tests.{" "}
        <a
          href="https://docs.usetusk.ai/automated-tests/overview"
          target="_blank"
          rel="noopener noreferrer"
          className="underline hover:text-purple-200"
        >
          Learn more
        </a>
      </p>
    </div>
  );
};
